import Axios from "axios";
import Cookies from "js-cookie";
import isEmpty from "lodash/isEmpty";
import {
  populateBasketFromServer,
  setUpdatingBasket,
  dispatchError,
  updateDeliveryCost,
  resetRestaurantBasket,
  clearError,
} from "../actions";
import KukdError from "../KukdError";
import bugsnagClient from "../../lib/bugsnag";

Axios.defaults.headers = { Pragma: "no-cache" };

class APIClass {
  getServerURL(req = null) {
    if (req && req.headers && req.headers.host) {
      return req.protocol + "://" + req.headers.host;
    } else {
      if (typeof window !== "undefined") {
        return window.location.origin;
      } else {
        return "";
      }
    }
    // return req && req.headers && req.headers.host ? req.protocol + '://' + req.headers.host : window.location.origin
  }

  getAPICredentials(req) {
    let kukdKey_ = req ? req.cookies.kukdKey : Cookies.get("kukdKey");
    let jwt = {
      kukdToken: req ? req.cookies.kukdToken : Cookies.get("kukdToken"),
      kukdKey:
        typeof kukdKey_ === "undefined" ? undefined : kukdKey_.toLowerCase(),
    };
    if (
      typeof jwt.kukdToken === "undefined" ||
      typeof jwt.kukdKey === "undefined"
    ) {
      return {};
    } else {
      return jwt;
    }
  }

  fetchSEOData(pathname, url = "") {
    let seoData = {};

    if (!pathname.length) {
      return seoData;
    } else if (pathname.length > 1) {
      // Remove preceding forward slash if not just a slash
      let hasPrecedingSlash = pathname.split("")[0] === "/";
      pathname = hasPrecedingSlash && pathname.substr(1);
    }

    return Axios.get(url + "/fetchSEOData", {
      params: { pathname, type: 2 }, // type: 1 = whitelabel, 2 = consumer
    })
      .then((res) => {
        if (res.data.title !== "") {
          seoData.title = res.data.title;
        }
        if (res.data.description !== "") {
          seoData.description = res.data.description;
        }
        if (res.data.keywords !== "") {
          seoData.keywords = res.data.keywords;
        }
        if (res.data.content !== "") {
          seoData.content = res.data.content;
        }

        return seoData;
      })
      .catch((err) => {
        return seoData;
      });
  }

  getHasKom(restaurantID, url = "") {
    return Axios.get(url + "/getHasKom", {
      params: { restaurantID: restaurantID },
    });
  }

  getOrderProgress(order_id, url = "") {
    return Axios.get(url + "/getOrderProgress", {
      params: { order_id: order_id },
    });
  }

  isGlobalOrderingDisabled(url = "") {
    return Axios.get(url + "/isGlobalOrderingDisabled");
  }

  getCuisines(url = "") {
    return Axios.get(url + "/getCuisines");
  }

  getPostcodeAreas(url = "") {
    return Axios.get(url + "/getPostcodeAreas");
  }

  getCuisineRestaurants(cuisineID, url = "") {
    return Axios.get(url + "/getCuisineRestaurants", {
      params: { cuisineID },
    });
  }

  getAreaRestaurants(areaCode, url = "") {
    return Axios.get(url + "/getAreaRestaurants", {
      params: { areaCode },
    });
  }

  // RESTAURANTS
  getRestaurants(postcode, url = "", user_id) {
    return Axios.get(url + "/getRestaurants", {
      params: { postcode, user_id },
      headers: this.getAPICredentials(),
    });
  }

  getRestaurant(restaurantID, url = "") {
    return Axios.get(url + "/getRestaurant", {
      params: { restaurantID: restaurantID },
      headers: this.getAPICredentials(),
    });
  }

  getAllFavouriteRestaurants(postcode, url = "") {
    return Axios.get(
      this.getServerURL() + url + "/rn/user/favourite/restaurants/",
      { postcode },
      { headers: this.getAPICredentials() }
    )
      .then((res) => {
        if (!res) {
          throw new Error("An expected error occured, cannot fetch data");
        }
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  getUserFavouriteRestaurants() {
    return Axios.get(this.getServerURL() + "/getUserFavouriteRestaurants", {
      headers: this.getAPICredentials(),
    })
      .then((res) => {
        if (!res) {
          throw new Error("An expected error occured, cannot fetch data");
        }
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  setUserFavouriteRestaurants(restaurant_id) {
    return Axios.post(
      this.getServerURL() + "/setUserFavouriteRestaurants",
      {
        restaurant_id,
      },
      {
        headers: this.getAPICredentials(),
      }
    )
      .then((res) => {
        if (!res) {
          throw new Error("an error occured with the response");
        }
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  // RESTAURANT
  getMenuData(restaurantID, menuID, url = "") {
    return Axios.get(url + "/getMenuData", {
      params: { restaurantID: restaurantID, menuID: menuID },
    });
  }
  getSetMeals(restaurantID, menuID, url = "") {
    return Axios.get(url + "/getSetMeals", {
      params: { restaurantID: restaurantID, menuID: menuID },
    });
  }
  getItemExtras(menuID, selectionData, url = "") {
    selectionData.menu_id = menuID;
    return Axios.get(url + "/getItemExtras", {
      params: selectionData,
    });
  }
  getReviews(restaurantID, url = "") {
    return Axios.get(url + "/getReviews", {
      params: { restaurantID: restaurantID },
    });
  }
  postReview(reviewData, url = "") {
    return Axios.post(
      url + "/postReview",
      { reviewData },
      {
        headers: this.getAPICredentials(),
      }
    );
  }
  requestReservation(restaurantID, reservationData, url = "") {
    return Axios.post(
      url + "/requestReservation",
      { restaurantID, reservationData },
      { headers: this.getAPICredentials() }
    );
  }
  getDeliveryTimings(restaurantID, url = "") {
    return Axios.get(url + "/getTimings", {
      params: { restaurantID: restaurantID, orderMode: "delivery" },
    });
  }
  getCollectionTimings(restaurantID, url = "") {
    return Axios.get(url + "/getTimings", {
      params: { restaurantID: restaurantID, orderMode: "collection" },
    });
  }

  setBasketDeliveryAddress(basket_uuid, address_id, url = "") {
    return Axios.put(
      url + "/setBasketDelAddress",
      { basket_uuid, address_id },
      { headers: this.getAPICredentials() }
    );
  }

  // BASKET
  getBasket(req, basket_uuid, restaurantID, storeDispatch) {
    return Axios.get(this.getServerURL(req) + "/getBasket", {
      headers: this.getAPICredentials(req),
      params: {
        basket_uuid: basket_uuid,
        restaurant_id: restaurantID,
      },
    })
      .then((res) => {
        if (storeDispatch) {
          switch (res.data.code) {
            case 200:
              storeDispatch(populateBasketFromServer(res.data.basket));
              break;
            case 404:
              // UNAUTHORISED USER - CLEAR REDUX BASKET
              // NO BASKET - CLEAR REDUX BASKET
              storeDispatch(resetRestaurantBasket(restaurantID));
              break;

            default:
              break;
          }
        }
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
  getRestaurantBasket(req, restaurantID, storeDispatch) {
    return Axios.get(this.getServerURL(req) + "/getRestaurantBasket", {
      headers: this.getAPICredentials(req),
      params: { restaurantID },
    })
      .then((res) => {
        switch (res.data.code) {
          case 200:
            storeDispatch(populateBasketFromServer(res.data.basket));
            break;
          case 404:
            // Server basket doesn't exist, reset basket in redux
            storeDispatch(resetRestaurantBasket(restaurantID));
            break;

          default:
            break;
        }

        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
  clearRestaurantBasket(req, restaurantID, storeDispatch) {
    return Axios.put(
      this.getServerURL(req) + "/clearRestaurantBasket",
      { restaurantID },
      { headers: this.getAPICredentials(req) }
    )
      .then((res) => {
        if (res.data.code === 200) {
          storeDispatch(populateBasketFromServer(res.data.basket));
        }

        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
  getLatestBasket(req, storeDispatch) {
    return Axios.get(this.getServerURL(req) + "/getLatestBasket", {
      headers: this.getAPICredentials(req),
    })
      .then((res) => {
        switch (res.data.code) {
          case 200:
            storeDispatch(populateBasketFromServer(res.data.basket));
            break;
        }

        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }

  basketQueue = this.resetBasketQueue();
  updatingBasket = false;
  updateTimeout = -1;
  resetBasketQueue() {
    return (this.basketQueue = {
      uuid: null,
      restaurant_id: null,
      deliveryCollectionState: null,
      deliveryAddress: null,
      deliveryCost: null,
      new_items: [],
      new_set_meals: [],
      remove_items: [],
      remove_set_meals: [],
      update_set_meals: [],
      voucherCode: null,
      removeVoucherCode: false,
      promoID: null,
      promoIDToRemove: null,
      reorder_of: null,
      feedAChildOptIn: null,
      basketList: [],
      selectedTimeSlot: null
    });
  }
  isBasketQueueEmpty() {
    if (
      isEmpty(this.basketQueue.new_items) &&
      isEmpty(this.basketQueue.new_set_meals) &&
      isEmpty(this.basketQueue.remove_items) &&
      isEmpty(this.basketQueue.remove_set_meals) &&
      isEmpty(this.basketQueue.update_set_meals) &&
      this.basketQueue.deliveryCollectionState == null &&
      this.basketQueue.deliveryAddress == null &&
      this.basketQueue.deliveryCost == null &&
      this.basketQueue.voucherCode == null &&
      this.basketQueue.removeVoucherCode == false &&
      this.basketQueue.promoID == null &&
      this.basketQueue.promoIDToRemove == null &&
      this.basketQueue.reorder_of == null &&
      this.basketQueue.feedAChildOptIn == null
    ) {
      return true;
    } else {
      return false;
    }
  }

  sendTimeSlotToServer(selectedTimeSlot, basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.selectedTimeSlot = selectedTimeSlot
    this.updateServerBasket(storeDispatch)
  }

  sendReOrderOfDataToServer(reorder_of, basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.reorder_of = reorder_of;
    this.updateServerBasket(storeDispatch);
  }
  sendDelColStateToServer(basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.deliveryCollectionState =
      basketData.deliveryCollectionState;
    this.updateServerBasket(storeDispatch);
  }
  updateDeliveryAddress(deliveryAddress, basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.deliveryAddress = deliveryAddress;
    this.updateServerBasket(storeDispatch);
  }
  updateDeliveryCost(deliveryCost, basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.deliveryCost = deliveryCost;
    this.updateServerBasket(storeDispatch);
  }
  sendPaymentTypeToServerBasket(chosenPaymentType, basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.chosenPaymentType = this.adjustPaymentIDForServerAPI(
      chosenPaymentType
    );
    this.updateServerBasket(storeDispatch);
  }
  updateFeedAChildToServerBasket(feedAChildOptIn, basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.feedAChildOptIn = feedAChildOptIn;
    this.updateServerBasket(storeDispatch);
  }
  addItemToServerBasket(itemData, basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.deliveryCollectionState =
      basketData.deliveryCollectionState;
    this.basketQueue.restaurant_id = itemData.restaurant_id;
    this.basketQueue.new_items.push(itemData);
    this.updateServerBasket(storeDispatch);
  }
  removeItemFromServerBasket(itemData, basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.deliveryCollectionState =
      basketData.deliveryCollectionState;
    this.basketQueue.restaurant_id = itemData.restaurant_id;
    this.basketQueue.remove_items.push(itemData);
    this.updateServerBasket(storeDispatch);
  }
  addSetMealToServerBasket(
    setMealData,
    basketData,
    restaurantID,
    storeDispatch
  ) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.deliveryCollectionState =
      basketData.deliveryCollectionState;
    this.basketQueue.restaurant_id = restaurantID;
    this.basketQueue.new_set_meals.push(setMealData);
    this.updateServerBasket(storeDispatch);
  }
  updateSetMealInServerBasket(
    originalHash,
    setMealData,
    basketData,
    restaurantID,
    storeDispatch
  ) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.deliveryCollectionState =
      basketData.deliveryCollectionState;
    this.basketQueue.restaurant_id = restaurantID;
    this.basketQueue.update_set_meals.push({
      original_hash: originalHash,
      set_meal: setMealData,
    });
    this.updateServerBasket(storeDispatch);
  }
  removeSetMealFromServerBasket(
    setMealData,
    basketData,
    restaurantID,
    storeDispatch
  ) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.deliveryCollectionState =
      basketData.deliveryCollectionState;
    this.basketQueue.restaurant_id = restaurantID;
    this.basketQueue.remove_set_meals.push(setMealData);
    this.updateServerBasket(storeDispatch);
  }

  applyVoucherCode(voucherCode, basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.voucherCode = voucherCode;
    this.updateServerBasket(storeDispatch);
  }
  removeVoucherCode(basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.removeVoucherCode = true;
    this.updateServerBasket(storeDispatch);
  }

  applyManualPromo(promoID, basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.promoID = promoID;
    this.updateServerBasket(storeDispatch);
  }
  removePromoByID(promoID, basketData, storeDispatch) {
    this.basketQueue.uuid = basketData.uuid;
    this.basketQueue.promoIDToRemove = promoID;
    this.updateServerBasket(storeDispatch);
  }

  awaitUpdateDelay(storeDispatch) {
    if (this.updateTimeout === -1) {
      this.updateTimeout = setTimeout(() => {
        this.updatingBasket = true;
        this.updateServerBasket(storeDispatch);
      }, 1000);
      return;
    } else {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = -1;
      this.updateServerBasket(storeDispatch);
    }
  }

  updateServerBasket(storeDispatch) {
    if (!this.updatingBasket) {
      this.awaitUpdateDelay(storeDispatch);
      return;
    }

    clearTimeout(this.updateTimeout);
    this.updateTimeout = -1;

    let basketQueueClone = { ...this.basketQueue };
    this.resetBasketQueue();

    Axios.put(
      this.getServerURL() + "/updateBasket",
      { basket_updates: basketQueueClone },
      { headers: this.getAPICredentials() }
    )
      .then((res) => {
        storeDispatch(clearError());

        this.updatingBasket = false;
        this.updateTimeout = -1;

        if (!this.isBasketQueueEmpty()) {
          // Send queued basket changes to server basket
          this.updateServerBasket(storeDispatch);
        } else {
          // Return new basket to redux
          storeDispatch(setUpdatingBasket(this.updatingBasket));
          storeDispatch(populateBasketFromServer(res.data.basket));
        }
      })
      .catch((err) => {
        this.updatingBasket = false;
        this.updateTimeout = -1;
        storeDispatch(setUpdatingBasket(this.updatingBasket));

        // SHOW SERVER ERROR RESPONSE MESSAGE TO USER
        if (!err.response || !err.response.data || !err.response.data.message) {
          storeDispatch(
            dispatchError(
              KukdError.BASKET_UPDATE_ERROR,
              "There has been a server error. Please refresh the page and try again."
            )
          );
        } else {
          storeDispatch(
            dispatchError(
              KukdError.BASKET_UPDATE_ERROR,
              err.response.data.message
            )
          );
        }
      });
  }

  getBaskets() {
    return Axios.get(this.getServerURL() + "/getBaskets", {
      headers: this.getAPICredentials(),
    })
      .then(res => {
        return res.data.baskets;
      })
      .catch((err) => {
        return err;
      });
  }

  linkBasketWithCustomer(basket_uuid, _dispatchError, errorType) {
    return Axios.put(
      this.getServerURL() + "/linkBasketWithCustomer",
      { basket_uuid },
      { headers: this.getAPICredentials() }
    )
      .then((res) => {
        localStorage.removeItem("basketUUID");
        return res.data;
      })
      .catch((err) => {
        Cookies.remove("kukdToken");
        Cookies.remove("kukdKey");

        if (_dispatchError && errorType) {
          _dispatchError(
            errorType,
            "There has been a problem with your Login. Please try again."
          );
        }
        return err;
      });
  }

  adjustPaymentIDForServerAPI(clientPaymentID) {
    switch (clientPaymentID) {
      case 0:
      case 1:
        // BANK
        return 3;
      case 2:
        // CASH
        return 1;
      case 3:
        // PAYPAL
        return 2;
      case 12:
        // CREDIT
        return 4;
      default:
        return -1;
    }
  }

  finaliseBasket(finaliseBasketData, logoutUser) {
    // SERVER API requires the following payment type id's
    finaliseBasketData.chosenPaymentType = this.adjustPaymentIDForServerAPI(
      finaliseBasketData.chosenPaymentType
    );

    return API.getUserData().then((res) => {
      switch (res.data.code) {
        case 200:
          return Axios.put(
            this.getServerURL() + "/finaliseBasket",
            { finaliseBasketData },
            { headers: this.getAPICredentials() }
          );

        case 401:
          // If the token is bad, remove it as well as the email key
          // Log the user out
          // unnecessary for bugsnag
          // bugsnagClient.notify(
          //   "There was a 401 on getUserData. Cleared key and token cookies.",
          //   {
          //     metaData: {
          //       res,
          //       finaliseBasketData
          //     }
          //   }
          // );

          Cookies.remove("kukdToken");
          Cookies.remove("kukdKey");

          logoutUser();

          throw new Error("User has been logged out.");

          return res.data;
        default:
          break;
      }
    });
  }

  setRescueStatusToRestoredFromEmail(basketUUID, url = "") {
    return Axios.put(
      url + "/setRescueStatusToRestoredFromEmail",
      { basketUUID },
      { headers: this.getAPICredentials() }
    );
  }

  checkDeliveryAllowed(restaurantID, address) {
    return Axios.get(this.getServerURL() + "/getDeliveryCharge", {
      params: {
        restaurantID: restaurantID,
        postcode: address.post_code.replace(" ", ""),
      },
    });
  }

  applyKukdPoints(restaurantID, basket_uuid, kukd_points_to_spend) {
    return Axios.put(
      this.getServerURL() + "/applyKukdPoints",
      {
        restaurantID: restaurantID,
        basket_uuid: basket_uuid,
        kukd_points_to_spend: kukd_points_to_spend,
      },
      { headers: this.getAPICredentials() }
    );
  }

  // EMAIL
  registerUser(userData) {
    return Axios.post(this.getServerURL() + "/registerUser", {
      userData: userData,
    });
  }

  // FACEBOOK
  getFBLoginConfig(url = "") {
    return Axios.get(url + "/getFBLoginConfig");
  }
  // registerFacebookUser(userData) {
  //   return Axios.post(this.getServerURL() + "/registerFacebookUser", {
  //     userData
  //   });
  // }
  loginFBUser(userData, url = "") {
    return Axios.get(url + "/loginFBUser", {
      params: userData,
    });
  }

  // GOOGLE
  getGoogleLoginConfig(url = "") {
    return Axios.get(url + "/getGoogleLoginConfig");
  }
  // registerGoogleUser(userData) {
  //   return Axios.post(this.getServerURL() + "/registerGoogleUser", {
  //     userData
  //   });
  // }
  loginGoogleUser(userData, url = "") {
    return Axios.get(url + "/loginGoogleUser", {
      params: userData,
    });
  }

  // USER ACCOUNT
  getUserData(req) {
    return Axios.get(this.getServerURL(req) + "/getUserData", {
      headers: this.getAPICredentials(req),
    });
  }
  updateUserData(userData) {
    return Axios.put(
      this.getServerURL() + "/updateUserData",
      { userData },
      { headers: this.getAPICredentials() }
    );
  }
  loginUser(email, password) {
    return Axios.post(this.getServerURL() + "/loginUser", {
      email: email.toLowerCase(),
      password: password,
    });
  }
  logoutUser(req) {
    return Axios.post(
      this.getServerURL(req) + "/logoutUser",
      {},
      {
        headers: this.getAPICredentials(),
      }
    );
  }
  forgotPassword(email) {
    return Axios.post(this.getServerURL() + "/forgotPasswordRequest", {
      email: email,
    });
  }
  resetPassword(password, token) {
    return Axios.post(this.getServerURL() + "/resetPasswordRequest", {
      password: password,
      token: token,
    });
  }

  // ADDRESSES
  getAllAddresses(req) {
    return Axios.get(this.getServerURL(req) + "/getAllAddresses", {
      headers: this.getAPICredentials(req),
    }).then((res) => {
      if (res.data.addresses) {
        let sortedAddresses = res.data.addresses.sort((a, b) => {
          if (a.default_address) {
            return -1;
          } else if (b.default_address) {
            return 1;
          } else {
            return 0;
          }
        });
        return { ...res.data, addresses: sortedAddresses };
      } else if (res.data.code === 404) {
        // This user has no addresses
        return { ...res.data, addresses: [] };
      } else {
        return { ...res.data, addresses: [] };
      }
    });
  }
  addUserAddress(addressData) {
    return Axios.post(
      this.getServerURL() + "/addUserAddress",
      { addressData },
      { headers: this.getAPICredentials() }
    );
  }
  updateUserAddress(addressData) {
    return Axios.put(
      this.getServerURL() + "/updateUserAddress",
      { addressData },
      { headers: this.getAPICredentials() }
    );
  }
  removeUserAddress(addressID) {
    return Axios.delete(this.getServerURL() + "/removeUserAddress", {
      params: { addressID },
      headers: this.getAPICredentials(),
    });
  }
  getAddressesByPostcode(postcode) {
    return Axios.get(this.getServerURL() + `/getAddressesByPostcode`, {
      params: { postcode },
      headers: this.getAPICredentials(),
    });
  }

  // ORDER HISTORY
  getOrderHistory(page, per_page = 10) {
    return Axios.get(this.getServerURL() + "/getOrderHistory", {
      params: {
        page,
        per_page,
      },
      headers: this.getAPICredentials(),
    });
  }
  getOrderHistoryDetails(orderID) {
    return Axios.get(this.getServerURL() + "/getOrderHistoryDetails", {
      params: { orderID },
      headers: this.getAPICredentials(),
    });
  }
  sendReOrder(orderID) {
    return Axios.get(this.getServerURL() + "/sendReOrder", {
      params: { orderID },
      headers: this.getAPICredentials(),
    });
  }

  // RESERVATION HISTORY
  getReservationHistory(page) {
    return Axios.get(this.getServerURL() + "/getReservationHistory", {
      params: { page },
      headers: this.getAPICredentials(),
    });
  }

  removeReservation = (id) => {
    return Axios.delete(this.getServerURL() + "/removeReservation", {
      params: { id },
      headers: this.getAPICredentials(),
    });
  };

  // PAYMENT CARDS
  getPaymentCards() {
    return Axios.get(this.getServerURL() + "/getPaymentCards", {
      headers: this.getAPICredentials(),
    });
  }
  removePaymentCard(cardID) {
    return Axios.delete(this.getServerURL() + "/removePaymentCard", {
      params: { cardID },
      headers: this.getAPICredentials(),
    }).then((res) => {
      switch (res.data.code) {
        case 200:
          return this.getPaymentCards();
        default:
          break;
      }
    });
  }

  // KUKD POINTS
  getKukdPoints() {
    return Axios.get(this.getServerURL() + "/getKukdPoints", {
      headers: this.getAPICredentials(),
    });
  }
  getKukdPointsTransactions() {
    return Axios.get(this.getServerURL() + "/getKukdPointsTransactions", {
      headers: this.getAPICredentials(),
    });
  }

  // MOBILE VERIFICATION
  isMobileVerified(mobile_number) {
    return Axios.post(
      this.getServerURL() + "/mobileVerification/check",
      { mobile_number },
      { headers: this.getAPICredentials() }
    );
  }
  requestMobileVerification(mobile_number) {
    return Axios.post(
      this.getServerURL() + "/mobileVerification/request",
      { mobile_number },
      { headers: this.getAPICredentials() }
    );
  }
  sendMobileVerification(mobile_number, verify_code) {
    return Axios.post(
      this.getServerURL() + "/mobileVerification/send",
      { mobile_number, verify_code },
      { headers: this.getAPICredentials() }
    );
  }

  // PAYMENTS
  paymentsInitCard(basketUUID, token, _c) {
    return Axios.get(this.getServerURL() + "/payments/init/card", {
      params: {
        basketUUID,
        token,
        _c,
      },
      headers: this.getAPICredentials(),
    });
  }
  paymentsInitCash(basketUUID) {
    return Axios.get(this.getServerURL() + "/payments/init/cash", {
      params: {
        basketUUID,
      },
      headers: this.getAPICredentials(),
    });
  }
  paymentsInitPayPal(basketUUID) {
    return Axios.get(this.getServerURL() + "/payments/init/paypal", {
      params: {
        basketUUID,
      },
      headers: this.getAPICredentials(),
    });
  }
  paymentsExecutePaypal(paypalPaymentResponse) {
    return Axios.post(this.getServerURL() + "/payments/paypal/execute", {
      basketUUID: paypalPaymentResponse.uuid,
      paymentId: paypalPaymentResponse.paymentId,
      token: paypalPaymentResponse.token,
      payerID: paypalPaymentResponse.PayerID,
    });
  }
  checkOrderStatus(basketUUID, req) {
    return Axios.get(this.getServerURL(req) + "/orders/status", {
      params: {
        basketUUID: basketUUID,
      },
      headers: this.getAPICredentials(req),
    });
  }
  reCheckOrderStatus(basketUUID) {
    return Axios.get(this.getServerURL() + "/orders/status", {
      params: {
        basketUUID: basketUUID,
      },
      headers: this.getAPICredentials(),
    });
  }
  fetchOrderBasket(orderUUID, req) {
    return Axios.get(this.getServerURL(req) + "/orders/basket", {
      params: {
        orderUUID: orderUUID,
      },
      headers: this.getAPICredentials(req),
    });
  }
  paymentsInitCredit(basketUUID) {
    return Axios.get(this.getServerURL() + "/payments/init/credit", {
      params: {
        basketUUID,
      },
      headers: this.getAPICredentials(),
    });
  }

  fetchFaqData() {
    return Axios.get(this.getServerURL() + "/faqdata");
  }

  sendContactForm(formData) {
    return Axios.post(this.getServerURL() + "/contactusform", {
      params: formData,
      headers: this.getAPICredentials(),
    });
  }

  
  notificationForRestaurant(restaurantID, email) {
    return Axios.post(
      this.getServerURL() + `/notifications/comingsoon/${restaurantID}`,
      { params: { email } },
      { headers: this.getAPICredentials() }
    );
  }

  referAFriend = (
    firstname,
    lastname,
    // phone,
    email
  ) => {
    return Axios.post(
      this.getServerURL() + "/referAFriend",
      {
        firstname,
        lastname,
        // phone,
        email,
      },
      { headers: this.getAPICredentials() }
    );
  };

  referAFriendRegistered = (uuid) => {
    return Axios.put(
      this.getServerURL() + "/referAFriend/registered",
      { uuid },
      { headers: this.getAPICredentials() }
    );
  };

  getMyReferrals = () => {
    return Axios.get(this.getServerURL() + "/myReferrals", {
      headers: this.getAPICredentials(),
    });
  };

  getReferralStatus = (uuid, url = "") => {
    return Axios.get(url + "/referralStatus", {
      params: { uuid },
    });
  };

  cancelMyReferral = (uuid) => {
    return Axios.put(
      this.getServerURL() + "/cancelMyReferral",
      { uuid }
      // { headers: this.getAPICredentials() }
    );
  };

  // checkReferFriendFirstOrder = req => {
  //   return Axios.get(this.getServerURL(req) + "/checkReferFriendFirstOrder", {
  //     headers: this.getAPICredentials(req)
  //   });
  // };

  recommendRestaurant = (name, town, postcode) => {
    return Axios.post(
      this.getServerURL() + "/recommendRestaurant",
      {
        name,
        town,
        postcode,
      },
      { headers: this.getAPICredentials() }
    );
  };

  getWhitelabelMenu(restaurantID, url = "") {
    return Axios.get(url + "/getWhitelabelMenu", {
      params: { restaurantID: restaurantID },
    });
  }

  wlLogin(token, url = "") {
    return Axios.get(url + "/wlLogin", {
      params: { token },
    });
  }

  getUserDataWL(url, headers) {
    return Axios.get(url + "/getUserData", {
      headers,
    });
  }
}

const API = new APIClass();
export default API;
