import Cookies from "js-cookie";

class WebViewCommsClass {
  loginResponseCallback = null;

  postMessage = msg => {
    typeof window !== "undefined" && window.nativePostMessage
      ? window.nativePostMessage(msg, "*")
      : window.postMessage && window.postMessage(msg, "*");
  };

  init() {
    if (typeof window !== "undefined" && typeof window !== "undefined") {
      setTimeout(() => {
        this.postMessage(JSON.stringify({ client_listening: true }));

        document.addEventListener(
          "message",
          ev => {
            let messageData = JSON.parse(ev.data);

            /**************************************************
             ***************LISTEN FOR IS WEB VIEW*************
             **************************************************/
            if (messageData.webview) {
              Cookies.set("webview", true, { secure: true });
              this.sendWebviewCookieStored();
            }
            /**************************************************/

            /**************************************************
             ************LISTEN FOR FB LOGIN RESPONSE**********
             **************************************************/
            if (
              messageData.hasOwnProperty("type") &&
              messageData.type === "facebook_login_response" &&
              this.loginResponseCallback !== null
            ) {
              this.loginResponseCallback(messageData);
            }
            /**************************************************/

            /**************************************************
             ************LISTEN FOR GOOGLE LOGIN RESPONSE**********
             **************************************************/
            if (
              messageData.hasOwnProperty("type") &&
              messageData.type === "google_login_response" &&
              this.loginResponseCallback !== null
            ) {
              this.loginResponseCallback({
                userID: messageData.authResponse.user.id,
                access_token: messageData.authResponse.accessToken
              });
            }
            /**************************************************/
          },
          false
        );
      }, 250);
    }
  }
  sendWebviewCookieStored() {
    if (typeof window !== "undefined") {
      this.postMessage(JSON.stringify({ webview_cookie_set: true }));
    }
  }
  sendFBLoginClicked(loginResponseCallback) {
    if (typeof window !== "undefined") {
      this.loginResponseCallback = loginResponseCallback;

      this.postMessage(JSON.stringify({ facebook_login_clicked: true }));
    }
  }

  sendGoogleLoginClicked(loginResponseCallback) {
    if (typeof window !== "undefined") {
      this.loginResponseCallback = loginResponseCallback;

      this.postMessage(JSON.stringify({ google_login_clicked: true }));
    }
  }

  userLoggedIn(userID) {
    if (typeof window !== "undefined") {
      this.postMessage(JSON.stringify({ user_logged_in: userID }));
    }
  }

  logoutClicked() {
    if (typeof window !== "undefined") {
      this.postMessage(JSON.stringify({ user_logged_out: true }));
    }
  }

  burgerMenuClicked() {
    if (typeof window !== "undefined") {
      this.postMessage(JSON.stringify({ burger_menu_clicked: true }));
    }
  }

  locationIconClicked() {
    if (typeof window !== "undefined") {
      this.postMessage(JSON.stringify({ location_icon_clicked: true }));
    }
  }
}

let WebViewComms = new WebViewCommsClass();

export default WebViewComms;
