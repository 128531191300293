export const SET_IS_SMOOTH_SCROLLING = "SET_IS_SMOOTH_SCROLLING";
export const UPDATE_ON_SCREEN_CATEGORIES = "UPDATE_ON_SCREEN_CATEGORIES";

export const setIsSmoothScrolling = val => ({
  type: SET_IS_SMOOTH_SCROLLING,
  val
});

export const updateOnScreenCategories = addRemoveInstructions => ({
  type: UPDATE_ON_SCREEN_CATEGORIES,
  addRemoveInstructions
});
