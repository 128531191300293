export const POPULATE_REORDER_DATA = "POPULATE_REORDER_DATA";
export const CLEAR_REORDER_DATA = "CLEAR_REORDER_DATA";
export const POPULATE_MISSING_ITEMS = "POPULATE_MISSING_ITEMS";
export const POPULATE_CHANGED_ITEMS = "POPULATE_CHANGED_ITEMS";

export const populateReOrderData = orderData => ({
  type: POPULATE_REORDER_DATA,
  orderData
});
export const clearReOrderData = () => ({
  type: CLEAR_REORDER_DATA
});
export const populateMissingItems = missingItems => ({
  type: POPULATE_MISSING_ITEMS,
  missingItems
});
export const populateChangedItems = changedItems => ({
  type: POPULATE_CHANGED_ITEMS,
  changedItems
});
