import {
  POPULATE_RESTAURANTS,
  POPULATE_SORTING_DATA,
  CHANGE_SELECTED_SORT_TYPE,
  CHANGE_SELECTED_SORT_DIRECTION,
  POPULATE_INITIAL_FILTERING_DATA,
  TOGGLE_BASIC_FILTER,
  TOGGLE_CUISINE_FILTER,
  DISPLAY_RESTAURANTS_FILTERS,
  SET_USER_FAVOURITE_RESTAURANT,
  REMOVE_USER_FAVOURITE_RESTAURANT,
  GET_ALL_USER_FAVOURITE_RESTAURANTS,
} from "../actions";

export const SORT_DISTANCE = "SORT_DISTANCE";
export const SORT_RATING = "SORT_RATING";
export const SORT_DELIVERY_COST = "SORT_DELIVERY_COST";
export const SORT_PRICE = "SORT_PRICE";
export const SORT_A_Z = "SORT_A_Z";
export const SORT_OFFERS = "SORT_OFFERS";
export const ASCENDING = "ASCENDING";
export const DESCENDING = "DESCENDING";

const restaurantListingData = (
  state = {
    filtering: {
      basicFilters: [],
      cuisineFilters: [],
      queryFilters: []
    },
    displayFilters: false,
    isFavourite: false,
  },
  action
) => {
  switch (action.type) {
    case POPULATE_RESTAURANTS:
      return {
        ...state,
        restaurants: action.restaurants
      };

    case POPULATE_SORTING_DATA:
      return {
        ...state,
        sorting: action.sorting
      };

    case CHANGE_SELECTED_SORT_TYPE:
      let typeArr = [];
      let newDir;
      state.sorting.sortOptions.forEach(opt => {
        // Create a clone of the object, so as not to edit it directly on the store object
        let newOpt = { ...opt };
        if (newOpt.type === action.sortData.type) {
          newOpt.selected = true;
          newDir = newOpt.direction;
        } else {
          newOpt.selected = false;
        }
        typeArr.push(newOpt);
      });

      return {
        ...state,
        sorting: {
          sortOptions: typeArr,
          currentType: action.sortData.type,
          currentDirection: newDir
        }
      };

    case CHANGE_SELECTED_SORT_DIRECTION:
      // Creat clones of sortOptions and the selected sort option
      let dirArr = [...state.sorting.sortOptions];
      let newOpt = { ...action.sortData };
      newOpt.direction =
        newOpt.direction === ASCENDING ? DESCENDING : ASCENDING;
      // Replace selected option in cloned array and push to store
      // The action of cloning and splicing is so that redux store
      // is not altered directly
      dirArr.splice(dirArr.indexOf(action.sortData), 1, newOpt);
      return {
        ...state,
        sorting: {
          sortOptions: dirArr,
          currentType: action.sortData.type,
          currentDirection: newOpt.direction
        }
      };

    case POPULATE_INITIAL_FILTERING_DATA:
      let totalAllowableFilters = 0;
      let markQueryFiltersForRemoval = false;
      let filtersNew = action.cuisines.map(cuisine => {
        if (cuisine.status === 1) {
          let count = getCuisineCount(cuisine.id, action.restaurants);
          totalAllowableFilters += count;

          // If we have navigated to /restaurants with filters in the url, apply them here
          let forceSelected = false;
          if (action.queryFilters && action.queryFilters.length) {
            markQueryFiltersForRemoval = true;
            if (action.queryFilters.indexOf(cuisine.id) !== -1) {
              forceSelected = true;
            }
          }

          return {
            id: cuisine.id,
            name: cuisine.name,
            selected: forceSelected,
            count: count
          };
        }
      });

      // Check if the 'All' filter is enabled from url queryFilters
      let forceSelected = false;
      if (action.queryFilters) {
        forceSelected = action.queryFilters.indexOf(0) !== -1;
      }
      // Create the 'All' filter and place it at the top
      filtersNew.unshift({
        id: 0,
        name: "All",
        selected: forceSelected,
        count: totalAllowableFilters
      });

      let delCount = 0;
      let colCount = 0;
      let resCount = 0;
      let comingSoonCount = 0;
      action.restaurants.map(restaurant => {
        if (restaurant.can_deliver) delCount++;
        if (restaurant.collection_status) colCount++;
        if (restaurant.reservation_status) resCount++;
        if (restaurant.is_coming_soon) comingSoonCount++;
      });
      let initialBasicFilters = [
        {
          id: 0,
          name: "All",
          selected: true,
          count: action.restaurants.length
        },
        { id: 1, name: "Delivery", selected: false, count: delCount },
        { id: 2, name: "Collection", selected: false, count: colCount },
        { id: 3, name: "Book a Table", selected: false, count: resCount }
      ];
      if (comingSoonCount > 0) {
        initialBasicFilters.push({
          id: 4,
          name: "Coming Soon",
          selected: false,
          count: comingSoonCount
        });
      }

      return {
        ...state,
        filtering: {
          basicFilters: initialBasicFilters,
          cuisineFilters: filtersNew,
          queryFilters: markQueryFiltersForRemoval ? [] : state.queryFilters
        }
      };

    case TOGGLE_BASIC_FILTER:
      let newFilters = [];
      // If toggling 'All' filter
      if (action.filterData.id === 0) {
        if (action.filterData.selected) {
          // If it's currently selected - do nothing
          return state;
        } else {
          // Otherwise set it to true, and all other filters to false
          newFilters = state.filtering.basicFilters.map(filter => {
            if (filter.id === 0) {
              return { ...filter, selected: true };
            } else {
              return { ...filter, selected: false };
            }
          });
        }
      } else {
        newFilters = state.filtering.basicFilters.map(filter => {
          if (filter.id === 0) {
            return { ...filter, selected: false };
          } else if (filter.id === action.filterData.id) {
            return { ...filter, selected: !filter.selected };
          } else {
            return { ...filter };
          }
        });

        let atLeastOneSelected = false;
        newFilters.forEach(filter => {
          if (filter.selected) atLeastOneSelected = filter.selected;
        });
        if (!atLeastOneSelected) {
          newFilters[0].selected = true;
        }
      }

      return {
        ...state,
        filtering: {
          basicFilters: newFilters,
          cuisineFilters: state.filtering.cuisineFilters
        }
      };

    case TOGGLE_CUISINE_FILTER:
      let updatedFilters = [];
      // If toggling 'All' filter
      if (action.filterData.id === 0) {
        if (action.filterData.selected) {
          // If it's currently selected - do nothing
          return state;
        } else {
          // Otherwise set it to true, and all other filters to false
          updatedFilters = state.filtering.cuisineFilters.map(filter => {
            if (filter.id === 0) {
              return { ...filter, selected: true };
            } else {
              return { ...filter, selected: false };
            }
          });
        }
      } else {
        updatedFilters = state.filtering.cuisineFilters.map(filter => {
          if (filter.id === 0) {
            return { ...filter, selected: false };
          } else if (filter.id === action.filterData.id) {
            return { ...filter, selected: !filter.selected };
          } else {
            return { ...filter };
          }
        });

        let atLeastOneSelected = false;
        updatedFilters.forEach(filter => {
          if (filter.selected) atLeastOneSelected = filter.selected;
        });
        if (!atLeastOneSelected) {
          updatedFilters[0].selected = true;
        }
      }

      return {
        ...state,
        filtering: {
          basicFilters: state.filtering.basicFilters,
          cuisineFilters: updatedFilters
        }
      };

    case DISPLAY_RESTAURANTS_FILTERS:
      return {
        ...state,
        displayFilters: action.val
      };


    case SET_USER_FAVOURITE_RESTAURANT:
      return {
        ...state,
        isUserFavourite: true
      }

    case REMOVE_USER_FAVOURITE_RESTAURANT:
      return {
        ...state,
        isUserFavourite: false
      }

    case GET_ALL_USER_FAVOURITE_RESTAURANTS:
      return {
        ...state,
        favouritesList: action.favourites
      }


    default:
      return state;
  }

  function getCuisineCount(cuisineID, restaurants) {
    let count = 0;
    restaurants.map((restaurant, index) => {
      let matchingCuisineType = restaurant.cuisine_types
        .split(",")
        .filter(typeID => parseInt(typeID) === cuisineID)[0];
      if (matchingCuisineType) {
        count++;
      }
    });
    return count;
  }
};
export default restaurantListingData;
