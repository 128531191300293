import moment from "moment";
import {
  updateRoutePath,
  setUserData,
  setLoggedIn,
  logoutUser
} from "../actions";
import API from "../api";
import Cookies from "js-cookie";
import bugsnagClient from "../../lib/bugsnag";
import Router from "next/router";

class UtilsClass {
  async handlePageGetInitialProps({
    store,
    isServer,
    pathname,
    req,
    query,
    asPath,
    pageConfig,
    seoPathnameOverride
  }) {
    // Update route path for breadcrumbs etc
    store.dispatch(updateRoutePath(asPath));
    let state = store.getState();
    // if (!state.accountData.loggedIn) {
    let kukdToken = Cookies.get("kukdToken");
    let kukdKey = Cookies.get("kukdKey");
    if (isServer && req) {
      kukdToken = req.cookies.kukdToken;
      kukdKey = req.cookies.kukdKey;
    }

    if (kukdToken && kukdKey) {
      let getUserDataResponse = await API.getUserData(req);
      switch (getUserDataResponse.data.code) {
        case 200:
          store.dispatch(setLoggedIn(true));
          store.dispatch(setUserData(getUserDataResponse.data.user));
          break;
        case 401:
          // If the token is bad, remove it as well as the email key

          bugsnagClient.notify(
            "There was a 401 on getUserData. Cleared key and token cookies.",
            {
              metaData: {
                req,
                getUserDataResponse,
                kukdToken,
                kukdKey,
                pathname,
                query,
                asPath
              }
            }
          );

          Cookies.remove("kukdToken");
          Cookies.remove("kukdKey");

          store.dispatch(logoutUser());

          break;

        default:
          break;
      }
    }
    // }
  }

  get localStorage() {
    if (typeof localStorage !== "undefined") {
      return localStorage;
    } else {
      return {};
    }
  }

  hash(s) {
    if (typeof s === "object") {
      s = JSON.stringify(s);
    } else if (typeof s === "number") {
      s = String(s);
    } else {
      return undefined;
    }

    var a = 0,
      c = 0,
      h,
      o;
    for (h = s.length - 1; h >= 0; h--) {
      o = s.charCodeAt(h);
      a = ((a << 6) & 268435455) + o + (o << 14);
      c = a & 266338304;
      a = c !== 0 ? a ^ (c >> 21) : a;
    }
    return String(a);
  }

  currencyFormat(val) {
    return val ? "£" + parseFloat((val * 100) / 100).toFixed(2) : "£0.00";
  }

  capitalise(a) {
    return a ? a.charAt(0).toUpperCase() + a.substr(1) : "";
  }

  capitaliseWords(str) {
    return str.split(" ").map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(" ");
  }

  camelCase(a) {
    return a
      ? a
          .split(" ")
          .map(b => {
            return b ? b.charAt(0).toUpperCase() + b.substr(1) : "";
          })
          .join(" ")
      : "";
  }

  checkForReturn(e, fn) {
    if (!fn) return;

    // Check if the user has pressed the return key
    if (e.nativeEvent.keyCode === 13) {
      fn();
    }
  }

  // TIMINGS UTILS
  parseTimingsToDays(timingsArray) {
    let timingDays = [[], [], [], [], [], [], []]; // 7 arrays for 7 days
    if (timingsArray) {
      timingsArray.map(timing => {
        timingDays[timing.day].push(timing);
      });
      timingDays.map(dayTimings => this.sortTimingsWithinDay(dayTimings));
    }
    return timingDays;
  }

  sortTimingsWithinDay(timings) {
    return timings.sort((a, b) => {
      let aOpenTime = moment(a.open_time, "hh:mm:ss");
      let bOpenTime = moment(b.open_time, "hh:mm:ss");
      // let aOpenTime = moment(a.open_time, "hh:mm")
      // let bOpenTime = moment(b.open_time, "hh:mm")
      return moment.min(aOpenTime, bOpenTime) === bOpenTime;
    });
  }

  generateOpenClosedString(timings) {
    let str = "";
    let currentDay = (moment().isoWeekday() - 1) % 7;
    let currentTiming = this.getCurrentTiming(timings[currentDay]);
    if (currentTiming) {
      // OPEN
      str =
        "Open Until - " +
        moment(currentTiming.close_time, "HH:mm:ss").format("HH:mm");
    } else if (!currentTiming) {
      // CLOSED
      let nextTiming = this.getNextTiming(timings, currentDay);
      if (nextTiming) {
        // Create a human readable sentence of next opening time
        // let openTime = moment()
        // .startOf("day")
        // .add(moment.duration(nextTiming.open_time, "hh:mm:ss"));

        let nextTimingMoment = moment(
          nextTiming.open_time,
          "hh:mm:ss"
        ).isoWeekday((nextTiming.day + 1) % 7);
        str = "Opens " + moment(nextTimingMoment).format("dddd HH:mm"); //.calendar();
      }
    }
    // if (!(str.match("AM$") || str.match("PM$")))
    //   str = str.substr(0, str.length - 3);
    return str;
  }

  getCurrentTiming(timings) {
    if (typeof timings === "undefined") return null;

    let currentTiming = null;
    let currentTime = moment();
    timings.map(timing => {
      if (currentTiming) return; // We already have the required timing

      let openTime = moment(timing.open_time, "hh:mm:ss");
      let closeTime = moment(timing.close_time, "hh:mm:ss");

      /* If closing time is before opening time,
      it should be the following day for later comparisons */
      if (moment.min(openTime, closeTime) === closeTime) {
        closeTime.add(1, "days");
      }

      // let openTime = moment(timing.open_time, "hh:mm")
      // let closeTime = moment(timing.close_time, "hh:mm")
      let minOpenTime = moment.min(currentTime, openTime);
      let minCloseTime = moment.min(currentTime, closeTime);
      if (minOpenTime === currentTime) {
        // Current Time is before opening time of timing
        // It's not this one
        return;
      } else if (minOpenTime === openTime && minCloseTime === currentTime) {
        // Current time is after opening time of timing
        // and is before closing time of timing
        currentTiming = timing;
        return;
      }
    });
    return currentTiming;
  }

  getNextTiming(timings, currentDay, dayCheckCount = 0) {
    const maxDaysToCheck = 7;
    if (dayCheckCount > maxDaysToCheck) return;

    let nextTiming = null;
    let currentTime = moment();

    timings[currentDay].map((timing, i, arr) => {
      if (nextTiming) return; // We already have the required timing

      // openTime needs to include the day as we could be comparing with a day that isn't today
      let openTime = moment()
        .startOf("isoWeek")
        .day(currentDay + 1)
        .add(moment.duration(timing.open_time, "hh:mm:ss"));

      // If we aren't checking a timing that is today
      // AND, the timing we're checking is Sunday
      // Add 1 week
      if (currentTime.day() - 1 !== currentDay) {
        if (currentDay === 0) {
          openTime.add(1, "weeks");
        }
      }

      let minOpenTime = moment.min(currentTime, openTime);
      if (minOpenTime === currentTime) {
        // Current time is before timing
        nextTiming = timing;
        return;
      } else {
        // There are no more timings today after currentTime
        return;
      }
    });

    // There are no more timings today after currentTime
    // Get first timing for next day with at least one timing
    if (!nextTiming) {
      let nextDay = (currentDay + 1) % 7;
      if (nextDay >= 7) {
        nextDay = 0;
      }
      nextTiming = this.getNextTiming(timings, nextDay, dayCheckCount + 1);
    }

    return nextTiming;
  }

  async checkDelColSelectionIsStillAllowed(
    restaurantID,
    deliveryCollectionState,
    showModalAction
  ) {
    let getRestaurantResponse = await API.getRestaurant(restaurantID);

    let restaurantData = getRestaurantResponse.data;
    let isDeliveryAvailableNow = Utils.isDeliveryAvailableNow(restaurantData);
    let isCollectionAvailableNow = Utils.isCollectionAvailableNow(
      restaurantData
    );

    if (
      (deliveryCollectionState.deliverySelected && !isDeliveryAvailableNow) ||
      (deliveryCollectionState.collectionSelected && !isCollectionAvailableNow)
    ) {
      // Del/Col is no longer available
      let delColStr = "Delivery";
      if (
        deliveryCollectionState.collectionSelected &&
        !isCollectionAvailableNow
      ) {
        delColStr = "Collection";
      }

      showModalAction({
        title: delColStr + " is not currently available for this restaurant.",
        description: <span>Please check restaurant timings.</span>,
        buttons: [
          {
            text: "OK",
            className: "green-button",
            onClick: () => {
              // Router.back();
              let pathname = "/restaurant";
              Router.push(
                {
                  pathname: pathname,
                  query: {
                    restaurantID: restaurantID
                  }
                },
                pathname + "/" + restaurantID
              );
            }
          }
        ]
      });
      return false;
    } else {
      return true;
    }
  }

  isDeliveryAvailableNow(restaurant) {
    return this._isAvailableNow(1, restaurant);
  }
  isCollectionAvailableNow(restaurant) {
    return this._isAvailableNow(2, restaurant);
  }
  _isAvailableNow = (type, restaurant) => {
    let timings = Utils.parseTimingsToDays(restaurant.timings);
    let currentDay = (moment().isoWeekday() - 1) % 7;
    let timingObj = Utils.getCurrentTiming(timings[currentDay]);
    if (!timingObj) {
      timingObj = Utils.getNextTiming(timings, currentDay);
    }
    if (!timingObj) return null;

    let lastTime = new moment(
      type === 1
        ? timingObj.del_last_order_time
        : timingObj.col_last_order_time,
      "HH:mm:ss"
    );
    let isTimingAvailableNow = lastTime.isAfter(new moment());
    return isTimingAvailableNow;
  };

  kukdPointsToGBP(points) {
    return this.currencyFormat(points / 100);
  }

  validateEmail = val => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
      return true;
    } else {
      return false;
    }
  };
}

let Utils = new UtilsClass();
export default Utils;
