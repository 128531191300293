import { SET_LOADING } from "../actions";
import NProgress from "nprogress";

const loading = (state = false, action) => {
  switch (action.type) {
    case SET_LOADING:
      if (action.loading) {
        NProgress.start();
      } else {
        NProgress.done();
      }

      return action.loading;
    default:
      return state;
  }
};

export default loading;
