export const POPULATE_RESTAURANT = "POPULATE_RESTAURANT";
export const UPDATE_SET_MEAL = "UPDATE_SET_MEAL";
export const ADD_SET_MEAL_SELECTION = "ADD_SET_MEAL_SELECTION";
export const DELETE_SET_MEAL_SELECTION = "DELETE_SET_MEAL_SELECTION";
export const RESET_SET_MEAL = "RESET_SET_MEAL";
export const DISPLAY_ONLY_BASKET = "DISPLAY_ONLY_BASKET";
export const DISPLAY_CATEGORIES = "DISPLAY_CATEGORIES";
export const EDITING_ITEM_WITH_OPTIONS = "EDITING_ITEM_WITH_OPTIONS";
export const SET_RESTAURANT_SECTION = "SET_RESTAURANT_SECTION";
export const SET_INITIAL_TAB = "SET_INITIAL_TAB";

export const populateRestaurantData = (restaurantData, menuData, setMeals) => ({
  type: POPULATE_RESTAURANT,
  restaurantData,
  menuData,
  setMeals
});
export const updateSetMeal = (setMeal, isOpen, isEditing) => ({
  type: UPDATE_SET_MEAL,
  setMeal,
  isOpen,
  isEditing
});
export const addSetMealSelection = (setMealID, selectionID, itemData) => ({
  type: ADD_SET_MEAL_SELECTION,
  setMealID,
  selectionID,
  itemData
});
export const deleteSetMealSelection = (
  selectionData,
  itemToDelete,
  ignoreExtras
) => ({
  type: DELETE_SET_MEAL_SELECTION,
  selectionData,
  itemToDelete,
  ignoreExtras
});
export const resetSetMeal = (setMealID, isOpen, isEditing) => ({
  type: RESET_SET_MEAL,
  setMealID,
  isOpen,
  isEditing
});
export const displayOnlyBasket = val => ({
  type: DISPLAY_ONLY_BASKET,
  val
});
export const displayCategories = val => ({
  type: DISPLAY_CATEGORIES,
  val
});
export const editingItemWithOptions = item => ({
  type: EDITING_ITEM_WITH_OPTIONS,
  item
});

export const setRestaurantSection = val => ({
  type: SET_RESTAURANT_SECTION,
  val
});

export const setInitialTab = tab => ({
  type: SET_INITIAL_TAB,
  tab
});
