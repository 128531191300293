import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import API from "../../api";
import {
  populateRestaurants,
  populateLocationData,
  dispatchError,
  clearError,
  setLoading,
} from "../../actions";
import Router from "next/router";
import InputKukd from "./InputKukd";
import KukdError from "../../KukdError";
import ErrorGeneric from "../error/ErrorGeneric";
import ButtonKukd from "./ButtonKukd";
import Cookies from "js-cookie";
import WebViewComms from "../../utils/WebViewComms";

class PostcodeSearch extends React.Component {
  _searching = false;

  constructor({
    autoFocus,
    autoSearch, //Passed in props
    location,
    loading, //Redux props
    dispatchError,
    clearError,
    populateLocationData,
    populateRestaurants,
    setLoading, //Redux dispatchers
  }) {
    super(...arguments);

    this.searchboxChanged = this.searchboxChanged.bind(this);
    this.searchPostcode = this.searchPostcode.bind(this);
    this.setSearchType = this.setSearchType.bind(this);

    this.state = {
      maxLength: 8,
      searchType: null,
      autoSearch: autoSearch || false,
    };
  }

  get searching() {
    return this._searching;
  }
  set searching(val) {
    this._searching = val;
    this.props.setLoading(val);
  }

  componentDidMount() {
    this.props.clearError();

    if (
      !isEmpty(this.props.location) &&
      !isEmpty(this.props.location.postcode)
    ) {
      this.refs.Searchbox.value = this.props.location.postcode.toUpperCase();
      if (this.state.autoSearch) {
        if (
          localStorage.defaultPostcode.toUpperCase() !==
          this.props.location.postcode.toUpperCase()
        ) {
          this.searchPostcode();
        }
      }
    } else {
      this.refs.Searchbox.value = localStorage.defaultPostcode || "";
    }
  }

  componentWillUnmount() {
    this.props.setLoading(false);
  }

  searchPostcode() {
    // this.props.setLoading(true)
    this.searching = true;

    // Get postcode from searchbox value
    let postcode = this.refs.Searchbox.value;
    if (postcode.length === 0 && !isEmpty(localStorage.defaultPostcode)) {
      // If no postcode entered, use previous saved postcode in localstorage
      postcode = localStorage.defaultPostcode; //|| "CF3 5UA"
      this.refs.Searchbox.value = localStorage.defaultPostcode = postcode;
    } else if (postcode.length === 0) {
      // this.props.setLoading(false)
      this.searching = false;
      this.props.dispatchError(
        KukdError.INVALID_POSTCODE,
        "Please enter a postcode"
      );
    }

    Promise.resolve()
      .then(() => {
        let serverURL = API.getServerURL();
        // If user didn't add a space to postcode, locationData
        // will return postcode with a space in the correct position
        postcode = localStorage.defaultPostcode = this.refs.Searchbox.value; // = locationData.data.postcode.toUpperCase();
        postcode = postcode.replace("-", "").toUpperCase();
        postcode = postcode.replace(" ", "").toUpperCase();
        // store locationData for future use
        this.props.populateLocationData(postcode);
        // Get restaurants from API - send lat,lon, returns an array of restaurants
        return API.getRestaurants(
          postcode,
          serverURL,
          this.props.accountData.loggedIn
            ? this.props.accountData.userData.id
            : null
        )
          .then((getRestaurantsResponse) => {
            return getRestaurantsResponse.data;
          })
          .catch((err) => {
            if (err.response && err.response.data.message)
              throw {
                kukdError: KukdError.NO_RESTAURANTS_FOR_POSTCODE,
                message: err.response.data.message,
              };
          });
      })
      .then((restaurants) => {
        // Send restaurants to redux store
        this.props.populateRestaurants(restaurants);

        // Replace space in postcode with hyphen for url reasons
        // postcode = postcode.replace(" ", "-");
        // Re-navigate to restaurants page, adding postcode as a route
        let pathname = "/restaurants";

        // If the page isn't 'changing' close Loader
        if (Router.route === pathname) {
          this.searching = false;
        }

        Router.push(
          {
            pathname: pathname,
            query: {
              postcode: postcode, // Pass the postcode also in query for convenience
              filters: 0, // Reset filter to 'All'
            },
          },
          pathname + "/" + postcode
        );
      })
      .catch((err) => {
        this.searching = false;
        this.props.dispatchError(err.kukdError, err.message);
      });
  }

  searchboxChanged(e) {
    // Whenever the search value changes, make sure it is capitalised
    this.refs.Searchbox.value = e.target.value.toUpperCase();

    // Enforce maxlength of 8 - fixes bug with samsung keyboards
    if (this.refs.Searchbox.value.length > this.state.maxLength) {
      this.refs.Searchbox.value = e.target.value.substr(0, 8);
    }

    this.props.clearError();
  }

  setSearchType(newSearchType) {
    this.setState({
      ...this.state,
      searchType: newSearchType,
    });
  }
  render() {
    let isWebview = Cookies.get("webview");
    let locationIcon = null;
    if (isWebview) {
      locationIcon = (
        <div
          className="d-flex justify-content-center"
          style={{
            backgroundColor: "white",
            border: "none",
            color: "#f49a19",
            minWidth: 50,
          }}
          onClick={() => WebViewComms.locationIconClicked()}
        >
          <span className="fas fa-map-marker-alt align-self-center" />
        </div>
      );
    }

    return (
      <div className="postcode-search">
        <InputKukd
          autoFocus={this.props.autoFocus}
          ref="Searchbox"
          type="text"
          placeholder="Enter Postcode"
          maxLength={this.state.maxLength}
          autoComplete="on"
          onReturnPressed={this.searchPostcode}
          onChange={this.searchboxChanged}
          className="postcode-input"
        />
        {/*ref="searchTypeSelect"*/}
        {/*id="searchSelect"*/}
        {/*indicatorClosed={<span className="fas fa-caret-down" />}*/}
        {/*indicatorOpen={<span className="fas fa-caret-up" />}*/}
        {/*options={[*/}
        {/*// { value: 'unset', pretty: 'Looking for...', placeholder: true },*/}
        {/*{ value: "takeaways", pretty: "Takeaways" },*/}
        {/*{ value: "reservations", pretty: "Reservations" }*/}
        {/*]}*/}
        {/*/>*/}
        {locationIcon}
        <ButtonKukd
          className="search-button"
          onClick={this.searchPostcode}
          loading={this.searching}
        >
          Find restaurants
          <span className="fas fa-search ml-2" />
        </ButtonKukd>

        <ErrorGeneric
          errorTypes={[
            KukdError.NO_RESTAURANTS_FOR_POSTCODE,
            KukdError.INVALID_POSTCODE,
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountData: state.accountData,
    location: state.location,
    loading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchError: (errorType, message) => {
      dispatch(dispatchError(errorType, message));
    },
    clearError: () => {
      dispatch(clearError());
    },
    populateLocationData: (locationData) => {
      dispatch(populateLocationData(locationData));
    },
    populateRestaurants: (restaurants) => {
      dispatch(populateRestaurants(restaurants));
    },
    setLoading: (loading) => {
      dispatch(setLoading(loading));
    },
  };
};

export default (PostcodeSearch = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostcodeSearch));
