export const POPULATE_INITIAL_FILTERING_DATA =
  "POPULATE_INITIAL_FILTERING_DATA";
export const TOGGLE_BASIC_FILTER = "TOGGLE_BASIC_FILTER";
export const TOGGLE_CUISINE_FILTER = "TOGGLE_CUISINE_FILTER";

export const populateInitialFilteringData = (
  cuisineFilters,
  cuisines,
  restaurants,
  queryFilters
) => ({
  type: POPULATE_INITIAL_FILTERING_DATA,
  cuisineFilters,
  cuisines,
  restaurants,
  queryFilters
});
export const toggleBasicFilter = filterData => ({
  type: TOGGLE_BASIC_FILTER,
  filterData
});
export const toggleCuisineFilter = filterData => ({
  type: TOGGLE_CUISINE_FILTER,
  filterData
});
