import { SET_GLOBAL_ORDERING_DISABLED } from "../actions";

const killswitchData = (
  state = {
    globalOrderingDisabled: false,
    banners: []
  },
  action
) => {
  switch (action.type) {
    case SET_GLOBAL_ORDERING_DISABLED:
      return {
        ...state,
        globalOrderingDisabled: action.isDisabled,
        banners: action.banners
      };

    default:
      return state;
  }
};
export default killswitchData;
