export const SET_CHECKOUT_STEP = "SET_CHECKOUT_STEP";
export const SET_SELECTED_TIMESLOT = "SET_SELECTED_TIMESLOT";
export const POPULATE_DELIVERY_ADDRESSES = "POPULATE_DELIVERY_ADDRESSES";
export const SET_SELECTED_ADDRESS = "SET_SELECTED_ADDRESS";
export const SET_EDITING_ADDRESS = "SET_EDITING_ADDRESS";
export const POPULATE_PAYMENT_CARDS = "POPULATE_PAYMENT_CARDS";
export const SET_SELECTED_PAYMENT_CARD = "SET_SELECTED_PAYMENT_CARD";
export const UPDATE_CARD_INFO = "UPDATE_CARD_INFO";
export const SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE";
export const SET_BILLING_ADDRESS = "SET_BILLING_ADDRESS";
export const UPDATE_MOBILE_TO_VERIFY = "UPDATE_MOBILE_TO_VERIFY";
export const UPDATE_MOBILE_VERIFICATION_REQUESTED =
  "UPDATE_MOBILE_VERIFICATION_REQUESTED";
export const UPDATE_VERIFICATION_CODE = "UPDATE_VERIFICATION_CODE";
export const UPDATE_MOBILE_IS_VERIFIED = "UPDATE_MOBILE_IS_VERIFIED";
export const UPDATE_PAYMENT_RESPONSE = "UPDATE_PAYMENT_RESPONSE";
export const UPDATE_SPECIAL_INSTRUCTIONS = "UPDATE_SPECIAL_INSTRUCTIONS";
export const SET_FETCHING_KUKD_POINTS = "SET_FETCHING_KUKD_POINTS";
export const SET_KUKD_POINTS_ENTERED = "SET_KUKD_POINTS_ENTERED";
export const SET_APPLYING_KUKD_POINTS = "SET_APPLYING_KUKD_POINTS";

export const setCheckoutStep = stepNo => ({
  type: SET_CHECKOUT_STEP,
  stepNo
});
export const setSelectedTimeslot = (timeslot, basketData, storeDispatch) => ({
  type: SET_SELECTED_TIMESLOT,
  timeslot,
  basketData,
  storeDispatch

});
export const populateDeliveryAddresses = (restaurant_id, addresses) => ({
  type: POPULATE_DELIVERY_ADDRESSES,
  restaurant_id,
  addresses
});
export const setSelectedAddress = (address, storeDispatch) => ({
  type: SET_SELECTED_ADDRESS,
  address,
  storeDispatch
});
export const setEditingAddress = (addressID, isEditing, storeDispatch) => ({
  type: SET_EDITING_ADDRESS,
  addressID,
  isEditing,
  storeDispatch
});
export const populatePaymentCards = cards => ({
  type: POPULATE_PAYMENT_CARDS,
  cards
});
export const setSelctedPaymentCard = card => ({
  type: SET_SELECTED_PAYMENT_CARD,
  card
});
export const updateCardInfo = card => ({
  type: UPDATE_CARD_INFO,
  card
});
export const setPaymentType = (paymentType, storeDispatch) => ({
  type: SET_PAYMENT_TYPE,
  paymentType,
  storeDispatch
});
export const setBillingAddress = billingAddress => ({
  type: SET_BILLING_ADDRESS,
  billingAddress
});
export const updateMobileToVerify = mobileNo => ({
  type: UPDATE_MOBILE_TO_VERIFY,
  mobileNo
});
export const updateMobileVerificationRequested = verificationRequested => ({
  type: UPDATE_MOBILE_VERIFICATION_REQUESTED,
  verificationRequested
});
export const updateVerificationCode = verificationCode => ({
  type: UPDATE_VERIFICATION_CODE,
  verificationCode
});
export const updateMobileIsVerified = isVerified => ({
  type: UPDATE_MOBILE_IS_VERIFIED,
  isVerified
});
export const updatePaymentResponse = data => ({
  type: UPDATE_PAYMENT_RESPONSE,
  data
});
export const updateSpecialInstructions = specialInstructions => ({
  type: UPDATE_SPECIAL_INSTRUCTIONS,
  specialInstructions
});
export const setFetchingKukdPoints = isFetchingPoints => ({
  type: SET_FETCHING_KUKD_POINTS,
  isFetchingPoints
});
export const setKukdPointsEntered = pointsToApply => ({
  type: SET_KUKD_POINTS_ENTERED,
  pointsToApply
});
export const setApplyingKukdPoints = isApplyingPoints => ({
  type: SET_APPLYING_KUKD_POINTS,
  isApplyingPoints
});
