import {
  POPULATE_RESTAURANT,
  UPDATE_SET_MEAL,
  ADD_SET_MEAL_SELECTION,
  RESET_SET_MEAL,
  DELETE_SET_MEAL_SELECTION,
  DISPLAY_ONLY_BASKET,
  DISPLAY_CATEGORIES,
  EDITING_ITEM_WITH_OPTIONS,
  SET_RESTAURANT_SECTION,
  SET_INITIAL_TAB,
} from "../actions";
import MenuUtils from "../utils/MenuUtils";
import isEqual from "lodash/isEqual";
import bugsnagClient from "../../lib/bugsnag";

const restaurantData = (
  state = {
    editingSetMeal: null,
    editingItemWithOptions: null,
    displayOnlyBasket: false,
    displayCategories: false,
    defaultTabIndex: 0,
    favouritesList: [],
  },
  action
) => {
  switch (action.type) {
    case POPULATE_RESTAURANT:
      if (action.setMeals) {
        action.setMeals = action.setMeals.map(setMeal => {
          let selections = resetSelections(setMeal, action.menuData[0]);

          return {
            ...setMeal,
            selections: selections,
            allSelectionsComplete: isAllSelectionsComplete(selections),
            isOpen: false,
            isEditing: false
          };
        });
      }

      return {
        ...state,
        restaurant: action.restaurantData,
        menu: action.menuData,
        setMeals: action.setMeals
      };

    case UPDATE_SET_MEAL:
      let setMealToUpdate = state.setMeals.filter(setMeal => {
        return setMeal.id === action.setMeal.id;
      })[0];

      setMealToUpdate.isOpen = action.isOpen;
      setMealToUpdate.isEditing = action.isEditing;

      let newSetMeals = [...state.setMeals];
      newSetMeals.splice(
        newSetMeals.indexOf(setMealToUpdate),
        1,
        action.setMeal
      );

      return {
        ...state,
        setMeals: newSetMeals,
        editingSetMeal: action.setMeal
      };

    case ADD_SET_MEAL_SELECTION:
      let newSetMeal = getSetMeal(action.setMealID);
      let selectionToUpdate = getSetMealSelection(
        newSetMeal,
        action.selectionID
      );
      selectionToUpdate.chosenItems = [
        ...selectionToUpdate.chosenItems,
        action.itemData
      ];

      newSetMeal.selections = updateSelections(
        [...newSetMeal.selections],
        selectionToUpdate
      );
      newSetMeal.allSelectionsComplete = isAllSelectionsComplete(
        newSetMeal.selections
      );

      return {
        ...state,
        setMeals: updateSetMeals([...state.setMeals], newSetMeal),
        editingSetMeal: newSetMeal
      };

    case DELETE_SET_MEAL_SELECTION:
      let setMealToAlter = getSetMeal(action.selectionData.set_meal_id);
      let selectionToAlter = getSetMealSelection(
        setMealToAlter,
        action.selectionData.id
      );

      let chosenItems = [...selectionToAlter.chosenItems];
      let isDeleted = false;
      chosenItems = chosenItems.filter(item => {
        if (action.ignoreExtras) {
          return item.id !== action.itemToDelete.id;
        }
        if (!isDeleted) {
          let areItemsEqual = isEqual(item, action.itemToDelete);
          if (areItemsEqual) {
            isDeleted = true;
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      });

      selectionToAlter.chosenItems = chosenItems;

      setMealToAlter.selections = updateSelections(
        [...setMealToAlter.selections],
        selectionToAlter
      );
      setMealToAlter.allSelectionsComplete = isAllSelectionsComplete(
        setMealToAlter.selections
      );

      return {
        ...state,
        setMeals: updateSetMeals([...state.setMeals], setMealToAlter),
        editingSetMeal: setMealToAlter
      };

    case RESET_SET_MEAL:
      let setMealToReset = getSetMeal(action.setMealID);

      setMealToReset.isOpen = action.isOpen;
      setMealToReset.isEditing = action.isEditing;

      // Remove all chosen items
      setMealToReset.selections = resetSelections(
        setMealToReset,
        state.menu[0]
      );
      setMealToReset.allSelectionsComplete = isAllSelectionsComplete(
        setMealToReset.selections
      );

      return {
        ...state,
        setMeals: updateSetMeals([...state.setMeals], setMealToReset),
        editingSetMeal: null
      };

    case DISPLAY_ONLY_BASKET:
      return {
        ...state,
        displayOnlyBasket: action.val
      };

    case DISPLAY_CATEGORIES:
      return {
        ...state,
        displayCategories: action.val
      };

    case EDITING_ITEM_WITH_OPTIONS:
      return {
        ...state,
        editingItemWithOptions: action.item
      };

    case SET_RESTAURANT_SECTION:
      return {
        ...state,
        section: action.val
      };

    case SET_INITIAL_TAB:
      return {
        ...state,
        defaultTabIndex: action.tab
      };

    default:
      return state;
  }

  function resetSelections(setMeal, menu) {
    let selections = setMeal.details.map(selection => {
      if (selection.selectable) {
        return { ...selection, chosenItems: [] };
      } else {
        let chosenItems = [];

        // Get all individual items
        if (selection.item_list !== "" && selection.item_list !== null) {
          chosenItems = selection.item_list.split(",").map(id => {
            return MenuUtils.getItemByID(menu, parseInt(id));
          });
        }
        chosenItems = [...chosenItems].filter(e => {
          if (typeof e === "undefined" || !e) {
            return false;
          } else {
            return true;
          }
        });

        // Get all items from each category
        if (
          selection.category_list !== "" &&
          selection.category_list !== null
        ) {
          selection.category_list.split(",").map(catID => {
            let catData = MenuUtils.getCatByID(menu, parseInt(catID));
            if (catData) {
              chosenItems = chosenItems.concat([...catData.items]);
            }
          });
        }

        // Remove any item exclusions
        if (
          selection.item_exclusions !== "" &&
          selection.item_exclusions !== null
        ) {
          selection.item_exclusions.split(",").map(excludedItemID => {
            chosenItems = chosenItems.filter(item => {
              return item.id !== parseInt(excludedItemID);
            });
          });
        }

        if (!chosenItems.length) {
          // comment setmeal selections missing unnecessary for bugsnag
          // bugsnagClient.notify(
          //   "A set meal selection is missing item_list and category_list. Should have at least one.",
          //   {
          //     metaData: {
          //       setMeal: setMeal,
          //       menu: menu
          //     }
          //   }
          // );
          return { ...selection, chosenItems: [], hasOptions: false };
        }

        if (
          chosenItems.length &&
          !chosenItems[0].extrastypesselections.length
        ) {
          return {
            ...selection,
            chosenItems: chosenItems,
            hasOptions: false
          };
        } else {
          return {
            ...selection,
            chosenItems: [],
            hasOptions: true
          };
        }
      }
    });

    return selections;
  }

  function getSetMeal(setMealID) {
    let setMeal = state.setMeals.filter(sm => {
      if (sm.id === setMealID) {
        return sm;
      }
    })[0];
    return { ...setMeal };
  }

  function getSetMealSelection(setMeal, selectionID) {
    let selection = setMeal.selections.filter(s => {
      if (s.id === selectionID) {
        return s;
      }
    })[0];
    return { ...selection };
  }

  function updateSelections(selections, newSelection) {
    let index = -1;
    selections.filter((selection, i) => {
      if (selection.id === newSelection.id) {
        index = i;
      }
    });
    selections.splice(index, 1, newSelection);

    return selections;
  }

  function updateSetMeals(setMeals, newSetMeal) {
    let index = -1;
    setMeals.filter((setMeal, i) => {
      if (setMeal.id === newSetMeal.id) {
        index = i;
      }
    });
    setMeals.splice(index, 1, newSetMeal);
    return setMeals;
  }

  function isAllSelectionsComplete(selections) {
    let allComplete = true;
    selections.map(selection => {
      if (!allComplete) return; //One selection has already told us that set meal is not complete

      if (selection.selectable === 0 && selection.hasOptions) {
        allComplete = selection.chosenItems.length > 0;
      } else if (
        selection.selectable &&
        selection.chosenItems.length !== selection.units
      ) {
        allComplete = false;
      }
    });
    return allComplete;
  }
};

export default restaurantData;
