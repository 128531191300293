import { POPULATE_CUISINES } from "../actions";

const cuisines = (state = [], action) => {
  switch (action.type) {
    case POPULATE_CUISINES:
      return action.cuisines;
    default:
      return state;
  }
};

export default cuisines;
