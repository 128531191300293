import { connect } from "react-redux";
import Link from "./Link";
import ButtonKukd from "./ButtonKukd";
import Cookies from "js-cookie";
import { setCookiesAccepted } from "../../actions";

class BannersGlobal extends React.Component {
  constructor({
    killswitchData //Redux Props
  }) {
    super(...arguments);
  }

  render() {
    if (!this.props.killswitchData.banners.length) return null;

    let banners = this.props.killswitchData.banners.map((banner, i) => {
      banners.push(
        <div key={i} dangerouslySetInnerHTML={{ __html: banner.content }} />
      );
    });

    return (
      <div id="banners-global" style={{ width: "100%" }}>
        {banners}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    killswitchData: state.killswitchData
  };
};

export default connect(
  mapStateToProps,
  null
)(BannersGlobal);
