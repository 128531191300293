export const SET_IS_WHITELABEL = "SET_IS_WHITELABEL";
export const POPULATE_WHITELABEL = "POPULATE_WHITELABEL";

export const setIsWhitelabel = isWhitelabel => ({
  type: SET_IS_WHITELABEL,
  isWhitelabel
});

export const populateWhitelabel = (restaurantURL, menuItems) => ({
  type: POPULATE_WHITELABEL,
  restaurantURL,
  menuItems
});
