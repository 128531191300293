export const POPULATE_SORTING_DATA = "POPULATE_SORTING_DATA";
export const CHANGE_SELECTED_SORT_TYPE = "CHANGE_SELECTED_SORT_TYPE";
export const CHANGE_SELECTED_SORT_DIRECTION = "CHANGE_SELECTED_SORT_DIRECTION";

export const populateSortingData = sorting => ({
  type: POPULATE_SORTING_DATA,
  sorting
});
export const changeSortType = sortData => ({
  type: CHANGE_SELECTED_SORT_TYPE,
  sortData
});
export const changeSelectedSortDirection = sortData => ({
  type: CHANGE_SELECTED_SORT_DIRECTION,
  sortData
});
