import { createStore } from "redux";
import reducer from "../reducers";

export const makeStore = (initialState, options) => {
  return createStore(reducer, initialState);
};

export * from "./killswitch";
export * from "./loading";
export * from "./account";
export * from "./kukdPoints";
export * from "./location";
export * from "./cuisine";
export * from "./restaurant";
export * from "./restaurants";
export * from "./sorting";
export * from "./filtering";
export * from "./basket";
export * from "./menuSearch";
export * from "./checkout";
export * from "./error";
export * from "./route";
export * from "./reOrder";
export * from "./modal";
export * from "./visibleCategories";
export * from "./whitelabel";
