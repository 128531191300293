import {
  CLEAR_REORDER_DATA,
  POPULATE_REORDER_DATA,
  POPULATE_MISSING_ITEMS,
  POPULATE_CHANGED_ITEMS
} from "../actions";

const basketData = (
  state = {
    orderData: {},
    missingItems: {
      items: [],
      setMeals: []
    },
    changedItems: {
      items: [],
      setMeals: []
    }
  },
  action
) => {
  switch (action.type) {
    case POPULATE_REORDER_DATA:
      return {
        ...state,
        orderData: action.orderData
      };
    case CLEAR_REORDER_DATA:
      return {
        ...state,
        orderData: {}
      };

    case POPULATE_MISSING_ITEMS:
      return {
        ...state,
        missingItems: action.missingItems
      };
    case POPULATE_CHANGED_ITEMS:
      return {
        ...state,
        changedItems: action.changedItems
      };

    default:
      return state;
  }
};

export default basketData;
