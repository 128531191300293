import { SET_KUKD_POINTS_DATA, SET_KUKD_POINTS_TRANSACTIONS } from "../actions";

const accountData = (
  state = {
    available: 0,
    pending: 0,
    total: 0
  },
  action
) => {
  switch (action.type) {
    case SET_KUKD_POINTS_DATA:
      return {
        ...state,
        available: action.data.kukd_points.kukd_points_total < 0 ? 0 : action.data.kukd_points.kukd_points_total,
        // pending: action.data.kukd_points.kukd_points.pending,
        total: action.data.kukd_points.kukd_points_total < 0 ? 0 : action.data.kukd_points.kukd_points_total,
        transactions: action.transactions
      };

    case SET_KUKD_POINTS_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions
      };

    default:
      return state;
  }
};
export default accountData;
