export const GET_BASKET_FROM_SERVER = "GET_BASKET_FROM_SERVER";
export const RESET_RESTAURANT_BASKET = "RESET_RESTAURANT_BASKET";
export const POPULATE_BASKET_FROM_SERVER = "POPULATE_BASKET_FROM_SERVER";
export const ADD_ITEM_TO_BASKET = "ADD_ITEM_TO_BASKET";
export const REMOVE_ITEM_FROM_BASKET = "REMOVE_ITEM_FROM_BASKET";
export const ADD_SET_MEAL_TO_BASKET = "ADD_SET_MEAL_TO_BASKET";
export const REMOVE_SET_MEAL_FROM_BASKET = "REMOVE_SET_MEAL_FROM_BASKET";
export const UPDATE_SET_MEAL_IN_BASKET = "UPDATE_SET_MEAL_IN_BASKET";
export const UPDATE_DELIVERY_COST = "UPDATE_DELIVERY_COST";
export const UPDATE_DELIVERY_COLLECTION_STATE =
  "UPDATE_DELIVERY_COLLECTION_STATE";
export const SET_UPDATING_BASKET = "SET_UPDATING_BASKET";
export const APPLY_KUKD_POINTS_TO_BASKET = "APPLY_KUKD_POINTS_TO_BASKET";
export const APPLY_VOUCHER_CODE_TO_BASKET = "APPLY_VOUCHER_CODE_TO_BASKET";
export const REMOVE_VOUCHER_CODE_FROM_BASKET =
  "REMOVE_VOUCHER_CODE_FROM_BASKET";
export const APPLY_MANUAL_PROMO_TO_BASKET = "APPLY_MANUAL_PROMO_TO_BASKET";
export const REMOVE_PROMO_FROM_BASKET = "REMOVE_PROMO_FROM_BASKET";
export const DISPLAY_FULLSCREEN_BASKET = "DISPLAY_FULLSCREEN_BASKET";
export const SEND_SELECTED_ADDRESS_TO_SERVER =
  "SEND_SELECTED_ADDRESS_TO_SERVER";
export const UPDATE_FEED_A_CHILD_CHARGE = "UPDATE_FEED_A_CHILD_CHARGE";
export const SET_DELIVERY_ADDRESS = "SET_DELIVERY_ADDRESS";
export const SET_BASKET_LIST = "GET_BASKET_LIST";

export const setDeliveryAddress = address => ({
  type: SET_DELIVERY_ADDRESS,
  address
});

export const setBasketList = baskets => ({
  type: SET_BASKET_LIST,
  baskets
})

export const getBasketFromServer = (
  req,
  basketUUID,
  restaurantID,
  storeDispatch
) => ({
  type: GET_BASKET_FROM_SERVER,
  req,
  basketUUID,
  restaurantID,
  storeDispatch
});
export const resetRestaurantBasket = restaurantID => ({
  type: RESET_RESTAURANT_BASKET,
  restaurantID
});
export const populateBasketFromServer = serverBasket => ({
  type: POPULATE_BASKET_FROM_SERVER,
  serverBasket
});
export const addItemToBasket = (newItem, storeDispatch) => ({
  type: ADD_ITEM_TO_BASKET,
  newItem,
  storeDispatch
});
export const removeItemFromBasket = (itemData, storeDispatch) => ({
  type: REMOVE_ITEM_FROM_BASKET,
  itemData,
  storeDispatch
});
export const addSetMealToBasket = (
  setMealData,
  restaurantID,
  storeDispatch
) => ({
  type: ADD_SET_MEAL_TO_BASKET,
  setMealData,
  restaurantID,
  storeDispatch
});
export const updateSetMealInBasket = (
  originalSetMeal,
  setMeal,
  restaurantID,
  storeDispatch
) => ({
  type: UPDATE_SET_MEAL_IN_BASKET,
  originalSetMeal,
  setMeal,
  restaurantID,
  storeDispatch
});
export const removeSetMealFromBasket = (
  setMealData,
  restaurantID,
  storeDispatch
) => ({
  type: REMOVE_SET_MEAL_FROM_BASKET,
  setMealData,
  restaurantID,
  storeDispatch
});
export const updateDeliveryCost = cost => ({
  type: UPDATE_DELIVERY_COST,
  cost
});
export const updateDeliveryCollectionState = (
  deliverySelected,
  collectionSelected,
  storeDispatch
) => ({
  type: UPDATE_DELIVERY_COLLECTION_STATE,
  deliverySelected,
  collectionSelected,
  storeDispatch
});
export const setUpdatingBasket = val => ({
  type: SET_UPDATING_BASKET,
  val
});
export const applyKukdPointsToBasket = (pointsToApply, storeDispatch) => ({
  type: APPLY_KUKD_POINTS_TO_BASKET,
  pointsToApply,
  storeDispatch
});
export const applyVoucherCodeToBasket = (voucherCode, storeDispatch) => ({
  type: APPLY_VOUCHER_CODE_TO_BASKET,
  voucherCode,
  storeDispatch
});
export const removeVoucherCodeFromBasket = storeDispatch => ({
  type: REMOVE_VOUCHER_CODE_FROM_BASKET,
  storeDispatch
});
export const applyManualPromo = (promoID, storeDispatch) => ({
  type: APPLY_MANUAL_PROMO_TO_BASKET,
  promoID,
  storeDispatch
});
export const removePromoFromBasket = (promoID, storeDispatch) => ({
  type: REMOVE_PROMO_FROM_BASKET,
  promoID,
  storeDispatch
});
export const displayFullscreenBasket = display => ({
  type: DISPLAY_FULLSCREEN_BASKET,
  display
});
export const sendSelectedAddressToServer = (
  address,
  deliveryCost,
  storeDispatch
) => ({
  type: SEND_SELECTED_ADDRESS_TO_SERVER,
  address,
  deliveryCost,
  storeDispatch
});
export const updateFeedAChildCharge = (feedAChildOptIn, storeDispatch) => ({
  type: UPDATE_FEED_A_CHILD_CHARGE,
  feedAChildOptIn,
  storeDispatch
});
