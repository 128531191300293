export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const showModal = config => ({
  type: SHOW_MODAL,
  config
});
export const hideModal = () => ({
  type: HIDE_MODAL
});
