import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import Utils from "../../utils/Utils";
import Router from "next/router";
import API from "../../api";
import { populateReOrderData, setLoading } from "../../actions";

class ReOrderEntry extends React.Component {
  constructor({
    orderData,
    populateReOrderData //Passed in props
  }) {
    super(...arguments);

    this.reOrderClicked = this.reOrderClicked.bind(this);
    this.reOrderView = this.reOrderView.bind(this);
    this.openRestaurantPage = this.openRestaurantPage.bind(this);
  }

  openRestaurantPage(restaurantID) {
    this.props.setLoading(true);

    let pathname = "/restaurant";
    Router.push(
      {
        pathname: pathname,
        query: {
          restaurantID: restaurantID
        }
      },
      pathname + "/" + restaurantID
    );
  }

  reOrderClicked() {
    this.props.populateReOrderData(this.props.orderData);

    let restaurantID = this.props.orderData.restaurant_id;
    let pathname = "/restaurant";
    Router.push(
      {
        pathname: pathname,
        query: {
          restaurantID: restaurantID
        }
      },
      pathname + "/" + restaurantID
    );
  }

  reOrderView() {
    this.props.setLoading(true);

    let pathname = "/order-view";
    Router.push(
      {
        pathname: pathname,
        query: {
          // orderID: this.props.orderData.id,
          basketUUID: this.props.orderData.basket_uuid
        }
      },
      pathname +
        // "/" +
        // this.props.orderData.id +
        "/" +
        this.props.orderData.basket_uuid
    );
  }

  render() {
    let orderData = this.props.orderData;
    let orderDate = moment(orderData.order_datetime).format("ddd, MMM Do YYYY");
    let orderTime = moment(orderData.order_datetime).format("h:mmA");
    let statusText = "";
    switch (orderData.status) {
      case 1:
      case 2:
        statusText = <span className="order-pending">Pending</span>;
        break;
      case 3:
        statusText = <span className="order-completed">Completed</span>;
        break;
      case 4:
        statusText = <span className="order-cancelled">Cancelled</span>;
        break;
    }
    return (
      <div className="re-order-entry">
        <span
          className="restaurant-logo"
          onClick={ev => this.openRestaurantPage(orderData.restaurant_id)}
        >
          <img
            src={`https://production-partner-cdn.s3.amazonaws.com/${
              orderData.restaurant_uuid
            }/logo_system.png`}
            alt={orderData.restaurant_name}
          />
        </span>
        <div className="re-order-details">
          <div
            className="re-order-header"
            onClick={ev => this.openRestaurantPage(orderData.restaurant_id)}
          >
            <h2>{orderData.restaurant_name}</h2>
            <h3>{orderData.restaurant_postcode}</h3>
          </div>
          <div className="re-order-body">
            <h2>Total: {Utils.currencyFormat(orderData.total_amount)}</h2>
            <div>
              <strong>Order number:</strong> {orderData.mode === 1 ? "D" : "C"}
              {orderData.id}
            </div>
            <div>
              <strong>Status:</strong> {statusText}
            </div>
            <div>
              <strong>Order Type:</strong> {orderData.order_mode === 1 ? "Delivery" : "Collection"}
            </div>
            <div>
              <strong>Date:</strong> {orderDate}
            </div>
            <div>
              <strong>Time:</strong> {orderTime}
            </div>
          </div>
          <div className="re-order-footer">
            <button className="gray-button" onClick={this.reOrderView}>
              View Order
            </button>
            {((orderData.available === 1 && orderData.status === 3) ||
              (orderData.available === 1 && orderData.status === 4)) && (
              <button
                disabled={orderData.status === 1 || orderData.status === 2}
                className="green-button"
                onClick={this.reOrderClicked}
              >
                Order Again
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLoading: loading => {
      dispatch(setLoading(loading));
    },
    populateReOrderData: orderData => {
      dispatch(populateReOrderData(orderData));
    }
  };
};
export default (ReOrderEntry = connect(
  null,
  mapDispatchToProps
)(ReOrderEntry));
