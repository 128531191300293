import React from "react";
import Utils from "../../utils/Utils";

class InputKukd extends React.Component {
  constructor({ onReturnPressed }) {
    super(...arguments);
  }

  get id() {
    return this.props.id;
  }

  get type() {
    return this.refs.inputKukd.type;
  }

  get value() {
    return this.refs.inputKukd.value;
  }
  set value(val) {
    this.refs.inputKukd.value = val;
  }

  get className() {
    return this.refs.inputKukd.className;
  }
  set className(val) {
    this.refs.inputKukd.className = val;
  }

  render() {
    // Remove props that shouldn't be directly applied to <input/>
    const { onReturnPressed, ...propsForInput } = this.props;
    return (
      <React.Fragment>
        <input
          ref="inputKukd"
          {...propsForInput}
          onKeyUp={e => Utils.checkForReturn(e, onReturnPressed)}
          data-hj-whitelist
        />
      </React.Fragment>
    );
  }
}

export default InputKukd;
