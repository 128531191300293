export const DISPATCH_ERROR = "DISPATCH_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const dispatchError = (errorType, message) => ({
  type: DISPATCH_ERROR,
  errorType,
  message
});
export const clearError = () => ({
  type: CLEAR_ERROR
});
