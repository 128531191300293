import {
  SET_IS_SMOOTH_SCROLLING,
  UPDATE_ON_SCREEN_CATEGORIES
} from "../actions";

const visibleCategoriesData = (
  state = {
    isSmoothScrolling: false,
    onScreenCategories: []
  },
  action
) => {
  switch (action.type) {
    case SET_IS_SMOOTH_SCROLLING:
      return { ...state, isSmoothScrolling: action.val };

    case UPDATE_ON_SCREEN_CATEGORIES:
      let catIDsToUpdate = [...state.onScreenCategories];
      action.addRemoveInstructions.map(instruction => {
        instruction.addCategoryIDs.map(catID => {
          if (catIDsToUpdate.indexOf(catID) < 0) {
            catIDsToUpdate.push(catID);
          }
        });
        instruction.removeCategoryIDs.map(catID => {
          let matchingCatIndex = catIDsToUpdate.indexOf(catID);
          if (matchingCatIndex >= 0) {
            catIDsToUpdate.splice(matchingCatIndex, 1);
          }
        });
      });

      return {
        ...state,
        onScreenCategories: catIDsToUpdate
      };

    default:
      return state;
  }
};

export default visibleCategoriesData;
