class KukdErrorClass {
  GENERIC = "GENERIC";
  SUCCESS = "SUCCESS";

  INVALID_LOGIN_CREDENTIALS = "INVALID_LOGIN_CREDENTIALS";
  EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS";
  INVALID_EMAIL_FORMAT = "INVALID_EMAIL_FORMAT";
  PASSWORD_REQUESTED = "PASSWORD_REQUESTED";
  PASSWORDS_DONT_MATCH = "PASSWORDS_DON'T MATCH";
  MISSING_EMAIL_ADDRESS = "MISSING_EMAIL_ADDRESS";
  NOT_LOGGED_IN = "NOT_LOGGED_IN";
  EMAIL_LOGIN_SERVER_ERROR = "EMAIL_LOGIN_SERVER_ERROR";
  SOCIAL_LOGIN_SERVER_ERROR = "SOCIAL_LOGIN_SERVER_ERROR";

  INVALID_POSTCODE = "INVALID_POSTCODE";
  NO_RESTAURANTS_FOR_POSTCODE = "NO_RESTAURANTS_FOR_POSTCODE";

  PERSONAL_INFO_REQUIRED = "PERSONAL_INFO_REQUIRED";
  ADDRESS_INFO_REQUIRED = "ADDRESS_INFO_REQUIRED";
  CHANGE_PASSWORD = "CHANGE_PASSWORD";

  RESERVATION_INVALID_TIME = "RESERVATION_INVALID_TIME";

  MOBILE_VERIFICATION = "MOBILE_VERIFICATION";

  KUKD_POINTS_ERROR = "KUKD_POINTS_ERROR";

  BASKET_UPDATE_ERROR = "BASKET_UPDATE_ERROR";

  DELIVERY_ADDRESS_ERROR = "DELIVERY_ADDRESS_ERROR";

  ORDER_VIEW_ERROR = "ORDER_VIEW_ERROR";
  REVIEW_SUBMIT_ERROR = "REVIEW_SUBMIT_ERROR";

  DELIVERY_TIMINGS_ERROR = "DELIVERY_TIMINGS_ERROR";
  COLLECTION_TIMINGS_ERROR = "COLLECTION_TIMINGS_ERROR";

  SERVER_ERROR = "SERVER_ERROR";
}
export default new KukdErrorClass();
