import { DISPATCH_ERROR, CLEAR_ERROR } from "../actions";

const error = (state = {}, action) => {
  switch (action.type) {
    case DISPATCH_ERROR:
      return { errorType: action.errorType, message: action.message };
    case CLEAR_ERROR:
      return {};
    default:
      return state;
  }
};

export default error;
