import { POPULATE_LOCATION_DATA } from "../actions";

const location = (state = {}, action) => {
  switch (action.type) {
    case POPULATE_LOCATION_DATA:
      return action.location;
    default:
      return state;
  }
};

export default location;
