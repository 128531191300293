import { POPULATE_WHITELABEL, SET_IS_WHITELABEL } from "../actions";

const whitelabelData = (
  state = { isWhitelabel: false, restaurantURL: "", menuItems: [] },
  action
) => {
  switch (action.type) {
    case SET_IS_WHITELABEL:
      return {
        ...state,
        isWhitelabel: action.isWhitelabel
      };

    case POPULATE_WHITELABEL:
      let orderedMenuItems = action.menuItems.sort((a, b) => a.order - b.order);
      orderedMenuItems = orderedMenuItems.filter(
        menuItem => menuItem.name !== "menu"
      );

      orderedMenuItems.push({
        descriptions: "Reviews",
        name: "reviews",
        order: 999,
        restaurant_id: orderedMenuItems[0].restaurant_id,
        url: "reviews"
      });
      return {
        ...state,
        restaurantURL: action.restaurantURL,
        menuItems: orderedMenuItems
      };
    default:
      return state;
  }
};

export default whitelabelData;
