import { combineReducers } from "redux";
import killswitchData from "./killswitchData";
import loading from "./loading";
import routePath from "./routePath";
import accountData from "./accountData";
import kukdPointsData from "./kukdPointsData";
import error from "./error";
import location from "./location";
import cuisines from "./cuisines";
import restaurantListingData from "./restaurantListingData";
import restaurantData from "./restaurantData";
import basketData from "./basketData";
import menuSearchData from "./menuSearchData";
import checkoutData from "./checkoutData";
import reOrderData from "./reOrderData";
import modalData from "./modalData";
import visibleCategoriesData from "./visibleCategoriesData";
import whitelabelData from "./whitelabelData";
import { reducer as burgerMenu } from "redux-burger-menu";

export default combineReducers({
  killswitchData,
  loading,
  routePath,
  accountData,
  kukdPointsData,
  error,
  location,
  cuisines,
  restaurantListingData,
  restaurantData,
  basketData,
  menuSearchData,
  checkoutData,
  reOrderData,
  modalData,
  visibleCategoriesData,
  whitelabelData,
  burgerMenu
});
