import { SEARCH_MENU, RESET_SEARCH_MENU } from "../actions";
import MenuUtils from "../utils/MenuUtils";

const menuSearchData = (
  state = { searchStr: "", setMealIDs: [], foundItems: [] },
  action
) => {
  switch (action.type) {
    case SEARCH_MENU:
      // If search string is empty, reset everything
      if (!action.searchStr.length) {
        return resetMenuSearchData();
      }

      let searchStr = action.searchStr.toLowerCase();
      let setMealIDs = [];
      let foundItems = [];

      // Search Categories
      action.menuData.map(menu => {
        menu.categories.map(cat => {
          if (cat.name.toLowerCase().indexOf(searchStr) !== -1) {
            // If cat has matching string, match all the items from that cat
            foundItems = foundItems.concat(cat.items);
          } else {
            cat.items.map(item => {
              if (itemContainsString(item, searchStr)) {
                foundItems.push(item);
              }
            });
          }
        });
      });

      // Search Set Meals
      action.setMeals.map(setMeal => {
        if (
          setMeal.name.toLowerCase().indexOf(searchStr) !== -1 ||
          setMeal.description.toLowerCase().indexOf(searchStr) !== -1
        ) {
          setMealIDs.push(setMeal.id);
        } else {
          let setMealHasAMatch = false;
          setMeal.details.map(selection => {
            if (setMealHasAMatch) return;

            let setMealHasAMatch = selection.item_list
              .split(",")
              .map(itemID => {
                if (setMealHasAMatch) return;

                let fetchedItem = MenuUtils.getItemByID(
                  action.menuData[0],
                  parseInt(itemID)
                );
                if (fetchedItem && itemContainsString(fetchedItem, searchStr)) {
                  setMealIDs.push(setMeal.id);
                  setMealHasAMatch = true;
                }
              });
          });
        }
      });

      return {
        ...state,
        searchStr: searchStr,
        setMealIDs: setMealIDs,
        foundItems: foundItems
      };

    case RESET_SEARCH_MENU:
      return resetMenuSearchData();

    default:
      return state;
  }
};

function resetMenuSearchData() {
  return {
    searchStr: "",
    setMealIDs: [],
    foundItems: []
  };
}

function itemContainsString(item, str) {
  if (
    (item.alias && item.alias.toLowerCase().indexOf(str) !== -1) ||
    (item.alias_ext && item.alias_ext.toLowerCase().indexOf(str) !== -1) ||
    (item.description && item.description.toLowerCase().indexOf(str) !== -1)
  ) {
    return true;
  } else {
    return false;
  }
}

export default menuSearchData;
