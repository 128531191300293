import React from "react";
import LinkNext from "next/link";
import AppRoutes from "../../../appRoutes";

class Link extends React.Component {
  constructor({}) {
    super(...arguments);
  }

  render() {
    let href = "";
    if (!this.props.pageName && this.props.href) {
      href = this.props.href;
    } else if (this.props.pageName) {
      href = AppRoutes.getRoute(this.props.pageName).href || "";
    }

    // Remove props that shouldn't be directly applied to <Input/>
    const { pageName, ...propsToUse } = this.props;
    return (
      <React.Fragment>
        <LinkNext {...propsToUse} href={href} />
      </React.Fragment>
    );
  }
}

export default Link;
