export const POPULATE_RESTAURANTS = "POPULATE_RESTAURANTS";
export const DISPLAY_RESTAURANTS_FILTERS = "DISPLAY_RESTAURANTS_FILTERS";
export const SORT_RESTAURANTS = "SORT_RESTAURANTS";
export const GET_ALL_USER_FAVOURITE_RESTAURANTS = "GET_ALL_USER_FAVOURITE_RESTAURANTS";
export const SET_USER_FAVOURITE_RESTAURANT = "SET_USER_FAVOURITE_RESTAURANT";
export const REMOVE_USER_FAVOURITE_RESTAURANT = "REMOVE_USER_FAVOURITE_RESTAURANT";

export const populateRestaurants = restaurants => ({
  type: POPULATE_RESTAURANTS,
  restaurants
});
export const displayRestaurantsFilters = val => ({
  type: DISPLAY_RESTAURANTS_FILTERS,
  val
});
export const getAllFavouriteRestaurants = favourites => ({
  type: GET_ALL_USER_FAVOURITE_RESTAURANTS,
  favourites
})
export const setUserFavouriteRestaurant = setFavourite => ({
  type: SET_USER_FAVOURITE_RESTAURANT,
  setFavourite
})