export const UPDATE_KUKD_TOKEN = "UPDATE_KUKD_TOKEN";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const SET_USER_DATA = "SET_USER_DATA";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_GOOGLE_USER_DATA = "SET_GOOGLE_USER_DATA";
export const SET_FACEBOOK_USER_DATA = "SET_FACEBOOK_USER_DATA";
export const SET_COOKIES_ACCEPTED = "SET_COOKIES_ACCEPTED";
export const GET_USER_FAVOURITES = "GET_USER_FAVOURITES";
export const REMOVE_USER_FAVOURITES = "REMOVE_USER_FAVOURITES";

export const getUserFavourites = (favourites) => ({
  type: GET_USER_FAVOURITES,
  favourites,
});
export const removeUserFavourites = (favourites) => ({
  type: REMOVE_USER_FAVOURITES,
  favourites,
});
export const setLoggedIn = (val) => ({
  type: SET_LOGGED_IN,
  val,
});
export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  userData,
});
export const logoutUser = () => ({
  type: LOGOUT_USER,
});
export const setGoogleUserData = (userData) => ({
  type: SET_GOOGLE_USER_DATA,
  userData,
});
export const setFacebookUserData = (userData) => ({
  type: SET_FACEBOOK_USER_DATA,
  userData,
});
export const setCookiesAccepted = (val) => ({
  type: SET_COOKIES_ACCEPTED,
  val,
});
export const getUserFavouriteRestaurants = (favourite) => ({
  type: GET_USER_FAVOURITE_RESTAURANTS,
  favourite,
});
