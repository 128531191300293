import React from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

class ErrorGeneric extends React.Component {
  constructor({
    errorTypes, // Passed in props
    error // Redux props
  }) {
    super(...arguments);
  }

  render() {
    let errorClassName;

    switch (this.props.error.errorType) {
      case "RESERVATION_INVALID_TIME":
        errorClassName = "generic-suggestion ";
        break;
      default:
        errorClassName = "generic-error ";
    }
    let errElement = (
      <div className={errorClassName + this.props.className}>
        {this.props.error.message}
      </div>
    );

    if (this.props.errorTypes.length) {
      if (this.props.errorTypes.indexOf(this.props.error.errorType) >= 0) {
        return errElement;
      } else {
        return null;
      }
    } else {
      if (isEmpty(this.props.error) === true) {
        return null;
      } else {
        return errElement;
      }
    }
  }
}
ErrorGeneric.defaultProps = {
  errorTypes: []
};
const mapStateToProps = state => {
  return {
    error: state.error
  };
};
export default (ErrorGeneric = connect(
  mapStateToProps,
  null
)(ErrorGeneric));
