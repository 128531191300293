import { UPDATE_ROUTE_PATH } from "../actions";

const routePath = (
  state = { currentRoutePath: "/", prevRoutePath: "/" },
  action
) => {
  switch (action.type) {
    case UPDATE_ROUTE_PATH:
      let fakePrevRoutePath = null;
      let routeSplit = state.currentRoutePath.split("/");
      let rootOfRoute = routeSplit.length > 1 ? routeSplit[1] : "";
      if (
        state.currentRoutePath === "/login" &&
        action.routePath === "/register"
      ) {
        // Check if coming from login & going to register
        fakePrevRoutePath = state.prevRoutePath;
      } else if (
        // Check if coming from register & going to login
        state.currentRoutePath === "/register" &&
        action.routePath === "/login"
      ) {
        fakePrevRoutePath = state.prevRoutePath;
      } else if (
        // Check if coming from forgot-password & going to login
        state.currentRoutePath === "/forgot-password" &&
        action.routePath === "/login"
      ) {
        fakePrevRoutePath = state.prevRoutePath;
      }

      // Check if on from restaurant id and updating url with restaurant name
      if (rootOfRoute === "restaurant") {
        if (isNaN(routeSplit[2])) {
          fakePrevRoutePath = state.prevRoutePath;
        }
      }

      // Check if coming from reset-password screen
      if (
        rootOfRoute === "reset-password" &&
        (action.routePath === "/login" || action.routePath === "/register")
      ) {
        fakePrevRoutePath = state.prevRoutePath;
      }

      return {
        ...state,
        prevRoutePath:
          fakePrevRoutePath !== null
            ? fakePrevRoutePath
            : state.currentRoutePath,
        currentRoutePath: action.routePath
      };

    default:
      return state;
  }
};
export default routePath;
