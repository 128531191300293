class MenuUtilsClass {
  getItemByID(menuData, id) {
    let selectedItem = null;
    menuData.categories.map(cat => {
      cat.items.map(item => {
        if (item.id === id) selectedItem = item;
      });
    });
    return selectedItem;
  }

  getCatByID(menuData, id) {
    let catData = menuData.categories.filter(cat => {
      return cat.id === id;
    });
    return catData[0];
  }

  sortSetMealSelectionTypes(selections) {
    // Sort selections by 'type' of selection
    let notSelectableButHasOptions = [];
    let selectable = [];
    let notSelectable = [];
    selections.map(s => {
      if (!s.selectable && s.hasOptions) {
        notSelectableButHasOptions.push(s);
      } else if (s.selectable) {
        selectable.push(s);
      } else {
        notSelectable.push(s);
      }
    });
    return notSelectableButHasOptions.concat(selectable, notSelectable);
  }
}
export default new MenuUtilsClass();
