import { SHOW_MODAL, HIDE_MODAL } from "../actions";
import isEmpty from "lodash/isEmpty";

const defaultModalData = {
  visible: false,
  config: {
    title: "Alert",
    description: "",
    overrideContent: "",
    buttons: [
      {
        text: "OK",
        className: "green-button",
        onClick: () => {}
      }
    ],
    animTime: 500,
    backgroundColor: "#94949499"
  }
};

const modalData = (state = defaultModalData, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      let newState = {
        ...state,
        visible: true,
        config: { ...defaultModalData.config }
      };

      if (action.config.title) {
        newState.config.title = action.config.title;
      }
      if (action.config.description) {
        newState.config.description = action.config.description;
      }
      if (action.config.overrideContent) {
        newState.config.overrideContent = action.config.overrideContent;
      }
      if (!isEmpty(action.config.buttons)) {
        newState.config.buttons = action.config.buttons;
      }
      if (action.config.animTime) {
        newState.config.animTime = action.config.animTime;
      }
      if (action.config.backgroundColor) {
        newState.config.backgroundColor = action.config.backgroundColor;
      }

      return newState;
    case HIDE_MODAL:
      return { visible: false, config: { ...defaultModalData.config } };
    default:
      return state;
  }
};

export default modalData;
