export const SEARCH_MENU = "SEARCH_MENU";
export const RESET_SEARCH_MENU = "RESET_SEARCH_MENU";

export const searchMenu = (searchStr, menuData, setMeals) => ({
  type: SEARCH_MENU,
  searchStr,
  menuData,
  setMeals
});
export const resetSearchMenu = () => ({
  type: RESET_SEARCH_MENU
});
