export const SET_KUKD_POINTS_DATA = "SET_KUKD_POINTS_DATA";
export const SET_KUKD_POINTS_TRANSACTIONS = "SET_KUKD_POINTS_TRANSACTIONS";

export const setKukdPointsData = data => ({
  type: SET_KUKD_POINTS_DATA,
  data
});
export const setKukdPointsTransactions = transactions => ({
  type: SET_KUKD_POINTS_TRANSACTIONS,
  transactions
});
