import React from "react";
import PropTypes from "prop-types";

class ButtonKukd extends React.Component {
  constructor({
    loading,
    loaderGraphic // Passed-in props
  }) {
    super(...arguments);

    this.state = {
      loading: loading
    };
  }

  componentWillReceiveProps(newProps) {
    this.loading = newProps.loading;
  }

  get loading() {
    return this.state.loading;
  }
  set loading(loading) {
    this.setState({ ...this.state, loading: loading });
  }

  render() {
    // Remove props that shouldn't be directly applied to <button>
    const { className, loading, loaderGraphic, ...propsForInput } = this.props;
    return (
      <React.Fragment>
        <button
          className={this.props.className + " button-kukd"}
          disabled={this.props.disabled || this.loading}
          {...propsForInput}
        >
          <span
            className={"align-self-center " + (this.loading ? "loading" : "")}
          >
            {this.props.children}
          </span>

          {this.loading && this.props.loaderGraphic}
        </button>
      </React.Fragment>
    );
  }
}
ButtonKukd.defaultProps = {
  loading: false,
  loaderGraphic: (
    <img className="button-loader" src="/static/img/buttonLoadingRipple.svg" />
  )
};
ButtonKukd.propTypes = {
  loading: PropTypes.bool,
  loaderGraphic: PropTypes.element
};

export default ButtonKukd;
